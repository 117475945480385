import React from 'react';
import './CourseCertCard.css';
import Button from '../../../../../../../../../../Components/Profile/Button/Button';
import { FaGraduationCap, FaLock, FaUnlock, FaCertificate, FaBookReader } from 'react-icons/fa';

const CourseCertCard = ({ courseName, progress, onViewCertificate }) => {
    const { completed, total, isUnlocked } = progress;
    const progressPercentage = (completed / total) * 100;

    return (
        <div className="skillbox-cert-wrapper">
            <div className="skillbox-cert-card">
                <div className="skillbox-cert-card__header">
                    <div className="skillbox-cert-card__icon-wrapper">
                        <FaGraduationCap className="skillbox-cert-card__icon" />
                    </div>
                    <div className="skillbox-cert-card__title-group">
                        <div className="skillbox-cert-card__title">{courseName}</div>
                        <div className={`skillbox-cert-card__status ${isUnlocked ? 'is-unlocked' : 'is-locked'}`}>
                            {isUnlocked ? (
                                <>
                                    <FaUnlock className="skillbox-cert-card__status-icon" />
                                    <span>Certificate Unlocked</span>
                                </>
                            ) : (
                                <>
                                    <FaLock className="skillbox-cert-card__status-icon" />
                                    <span>Certificate Locked</span>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                
                <div className="skillbox-cert-card__content">
                    <div className="skillbox-cert-card__progress-container">
                        <div className="skillbox-cert-card__progress-text">
                            <FaBookReader className="skillbox-cert-card__progress-icon" />
                            {/* <span>{completed}/{total} Modules Completed</span> */}
                            <span>Waiting for mentor approval to unlock certificate</span>
                        </div>
                        <div className="skillbox-cert-card__progress-bar">
                            <div 
                                className="skillbox-cert-card__progress-fill" 
                                style={{ width: `${progressPercentage}%` }}
                            />
                        </div>
                    </div>

                    <div className="skillbox-cert-card__button-container">
                    <Button
                        text={
                            <>
                                <span>{isUnlocked ? "View Certificate" : "Complete More Modules"}</span>
                            </>
                        }
                        onClick={() => onViewCertificate(progress.certificate)}
                        width="auto"
                        disabled={!isUnlocked}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CourseCertCard;
