import React, { useState } from 'react';
import Info from '../../Components/Info';
import Communication from './pages/Communication';

const CommunicationBasedInterview = ({ 
  isLoading, 
  error, 
  questionLevel, 
  setSelectedLevel, 
  selectedLevel, 
  startInterviewHandler, 
  selectedInterviewType, 
  handleGoToHome, 
  handleGoToAllInterview 
}) => {
  const [showCommunication, setShowCommunication] = useState(false);

  const handleShowCommunication = () => {
    setShowCommunication(true);
  };

  return (
    <>
      {!showCommunication ? (
        <Info
          selectedInterviewType={selectedInterviewType}
          handleGoToHome={handleGoToHome}
          handleGoToAllInterview={handleGoToAllInterview}
          onNext={handleShowCommunication}
        />
      ) : (
        <Communication
          selectedInterviewType={selectedInterviewType}
          startInterviewHandler={startInterviewHandler}
          isLoading={isLoading}
          error={error}
          level={questionLevel}
          setSelectedLevel={setSelectedLevel}
          selectedLevel={selectedLevel}
          handleGoToHome={handleGoToHome}
          handleGoToAllInterview={handleGoToAllInterview}
          handleGoToCommunicationBased={setShowCommunication}
        />
      )}
    </>
  );
};

export default CommunicationBasedInterview;

