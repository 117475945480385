import React, { useState, useEffect, useContext } from "react";
import "./Education.css";
import Button from "../../../../Components/Profile/Button/Button";
import Popup from "../../../../Components/Profile/Popup/PopUp";
import { EducationType } from "../Data/SelectData";
import { useHttpClient } from "../../../../Components/Hooks/http-hook";
import { AuthContext } from "../../../../context/auth-context";
import Loading from "../../../../Components/Loading";
import { aggregateType } from "../Data/SelectData";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import Delete from "../Popups/Delete";
import { useNavigate } from "react-router";


const Education = () => {
  const history = useNavigate();
  const [pageLoading, setPageLoading] = useState(true);
  const auth = useContext(AuthContext);
  const { sendRequest, error: requestError } = useHttpClient();
  const [educationList, setEducationList] = useState([]);
  const [showAddEducationPopup, setShowAddEducationPopup] = useState(false);
  const [EducationComponent, setEducationComponent] = useState(
    EducationType[0]
  );
  const [isEducationComponent, setIsEducationComponent] = useState(false);
  const [selectedEducationType, setSelectedEducationType] = useState(null);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteEducationId, setDeleteEducationId] = useState(null);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editEducationData, setEditEducationData] = useState(null);

  const showDeleteConfirmation = (id) => {
    setDeleteEducationId(id);
    setShowDeletePopup(true);
  };

  const closeDeleteConfirmation = () => {
    setDeleteEducationId(null);
    setShowDeletePopup(false);
  };

  const showEditEducationPopup = (data) => {
    setEditEducationData(data);
    setShowEditPopup(true);
    setSelectedEducationType(
      EducationType.find((item) => item.value === data.type)
    );
    setIsEducationComponent(true);
    setEducationComponent(
      EducationType.find((item) => item.value === data.type)
    );
  };

  const getLabelofAggregateType = (value) => {
    let label = "";
    aggregateType.forEach((item) => {
      if (item.value === value) {
        label = item.label;
      }
    });
    return label;
  };

  const changeTimestampToDaysAgo = (timestamp) => {
    const date = moment(timestamp);
    const currentDate = moment();
    const daysAgo = currentDate.diff(date, "days");
    if (daysAgo === 0) {
      return "Updated today";
    } else if (daysAgo > 30) {
      const monthsAgo = currentDate.diff(date, "months");
      return `${monthsAgo} months ago`;
    } else {
      return `${daysAgo} days ago`;
    }
  };

  const deleteEducation = async (id) => {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/profile/education/${id}`,
      "DELETE",
      null,
      {
        "Content-Type": "application/json",
        "Refresh-Token": auth.refresh_token,
        Authorization: auth.token,
      }
    );
    if (responseData && responseData.message) {
      toast(responseData.message, {
        autoClose: 3000,
        type: toast.TYPE.SUCCESS,
      });
      setShowDeletePopup(false);
      const updatedEducationList = educationList.filter(
        (item) => item.id !== id
      );
      setEducationList(updatedEducationList);
    }
  };

  const addNewEducation = async (data) => {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/profile/education`,
      "POST",
      JSON.stringify(data),
      {
        "Content-Type": "application/json",
        "Refresh-Token": auth.refresh_token,
        Authorization: auth.token,
      }
    );
    if (responseData && responseData.data) {
      toast(responseData.message, {
        autoClose: 3000,
        type: toast.TYPE.SUCCESS,
      });
      setShowAddEducationPopup(false);
      const updatedEducationList = [
        ...educationList,
        {
          ...responseData.data,
          created_at: "Just now",
        },
      ];
      setEducationList(updatedEducationList);
      setEducationComponent(null);
      setSelectedEducationType(null);
    }
  };

  const editEducation = async (data) => {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/profile/education/${data.id}`,
      "PUT",
      JSON.stringify(data),
      {
        "Content-Type": "application/json",
        "Refresh-Token": auth.refresh_token,
        Authorization: auth.token,
      }
    );
    if (responseData && responseData.data) {
      toast(responseData.message, {
        autoClose: 3000,
        type: toast.TYPE.SUCCESS,
      });
      setShowEditPopup(false);
      const updatedEducationList = educationList.map((item) =>
        item.id === data.id
          ? { ...responseData.data, created_at: "Just Now" }
          : item
      );
      setEducationList(updatedEducationList);
      setEducationComponent(null);
    }
  };

  useEffect(() => {
    const getEducationDetails = async () => {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/profile/educations`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      if (responseData && responseData.data) {
        setEducationList(
          responseData.data.map((item) => ({
            ...item,
            created_at: changeTimestampToDaysAgo(item.update_timestamp),
          }))
        );
      }
      setPageLoading(false);
    };
    getEducationDetails();
  }, []);

  useEffect(() => {
    if (requestError) {
      toast(requestError, {
        autoClose: 3000,
        type: toast.TYPE.ERROR,
      });
      setPageLoading(false);
    }
  }, [requestError]);

  return (
    <>
    <div
      className="stu-skillbox-nav-header"
      onClick={() => history("/skillbox-v2")}
    >
      Home
    </div>
    <div className="profile-education-page">
      <div className="profile-education-header">
        <div className="profile-education-header-title">
          My Profile - Education Details
        </div>
        {educationList && educationList.length > 0 && (
          <Button
            text="Add New"
            width="100px"
            onClick={() => setShowAddEducationPopup(true)}
          />
        )}
      </div>
      {pageLoading ? (
        <div className="profile-loader-container">
          <div className="profile-loader-sub-container">
            <Loading
              color="#14144e"
              customClass="profile-loader-container-for-loader"
            />
          </div>
        </div>
      ) : (
        <>
          {showAddEducationPopup && (
            <>
              {isEducationComponent && EducationComponent ? (
                <EducationComponent.component
                  type={EducationComponent.value}
                  title={EducationComponent.label}
                  onClose={() => {
                    setShowAddEducationPopup(false);
                    setIsEducationComponent(false);
                    setSelectedEducationType(null);
                    setEducationComponent(null);
                  }}
                  HandleSubmitFunction={addNewEducation}
                  show={showAddEducationPopup}
                  isEdit={false}
                />
              ) : (
                <Popup
                  title={
                    isEducationComponent && selectedEducationType
                      ? selectedEducationType.label
                      : "Add Education Details"
                  }
                  onClose={() => {
                    setShowAddEducationPopup(false);
                    setIsEducationComponent(false);
                    setSelectedEducationType(null);
                  }}
                  show={showAddEducationPopup}
                >
                  <div className="profile-education-popup-education-type-container">
                    <div className="profile-education-popup-education-type-title">
                      Select Type
                    </div>
                    {EducationType.map((education, i) => (
                      <div
                        className={
                          selectedEducationType == education
                            ? "profile-education-popup-type-item profile-education-popup-type-item-selected "
                            : "profile-education-popup-type-item"
                        }
                        key={i}
                        onClick={() => {
                          setSelectedEducationType(education);
                        }}
                      >
                        {education.label}
                      </div>
                    ))}
                    <Button
                      text="SELECT"
                      width="250px"
                      onClick={() => {
                        if (selectedEducationType) {
                          setIsEducationComponent(true);
                          setEducationComponent(selectedEducationType);
                        }
                      }}
                      disabled={!selectedEducationType}
                    />
                  </div>
                </Popup>
              )}
            </>
          )}
          <ToastContainer closeButton={false} />
          {educationList && educationList.length > 0 ? (
            <div className="profile-education-section-1">
              {educationList.map((education) => (
                <div className="profile-education-card" key={education.id}>
                  <div className="profile-education-card-header">
                    <div className="profile-education-card-header-section-1">
                      <div className="profile-education-card-header-title">
                        {education.degree && education.degree}
                      </div>
                      <div className="profile-education-card-header-subtitle">
                        {education.institution && education.institution}
                        {education.board && education.board !=="NA" && (<span> | {education.board}</span>)}
                      </div>
                    </div>
                    <div className="profile-education-card-header-section-2">
                      <div className="profile-education-card-body-title-1">
                        {education.created_at ? education.created_at : "NA"}
                      </div>
                    </div>
                  </div>
                  <div className="profile-education-card-body">
                    <div className="profile-education-card-body-section-1">
                      <div className="profile-education-card-body-title">
                        Year of Completion
                      </div>
                      <div className="profile-education-card-body-data-value">
                        {education.year_of_completion &&
                          education.year_of_completion}
                      </div>
                    </div>
                    <div className="profile-education-card-body-section-2">
                      <div className="profile-education-card-body-title">
                        Stream
                      </div>
                      <div className="profile-education-card-body-data-value">
                        {education.stream ? education.stream : "NA"}
                      </div>
                    </div>
                    <div className="profile-education-card-body-section-3">
                      <div className="profile-education-card-body-title">
                        Aggregate
                      </div>
                      <div className="profile-education-card-body-data-value">
                        {education.marks_type &&
                          getLabelofAggregateType(education.marks_type)}{" "}
                        - {education.marks_obtained}/{education.max_marks}
                      </div>
                    </div>
                  </div>
                  <div className="profile-education-card-footer">
                    <div className="profile-education-card-edit">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.41421 15.8895L16.5563 5.74738L15.1421 4.33316L5 14.4753V15.8895H6.41421ZM7.24264 17.8895H3V13.6468L14.435 2.21184C14.8256 1.82132 15.4587 1.82132 15.8492 2.21184L18.6777 5.04027C19.0682 5.43079 19.0682 6.06395 18.6777 6.45447L7.24264 17.8895ZM3 19.8895H21V21.8895H3V19.8895Z"
                          fill="#004C73"
                        />
                      </svg>
                      <div
                        className="profile-education-card-edit-title"
                        onClick={() => showEditEducationPopup(education)}
                      >
                        Edit Details
                      </div>
                    </div>
                    <div
                      className="profile-education-card-delete"
                      onClick={() => showDeleteConfirmation(education.id)}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7 4V2H17V4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7ZM6 6V20H18V6H6ZM9 9H11V17H9V9ZM13 9H15V17H13V9Z"
                          fill="#004C73"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="profile-education-section-2">
              <div className="profile-education-add-new">
                <div className="profile-education-add-new-title">
                  Add your education details
                </div>
                <div className="profile-education-add-new-subtitle">
                  Your school / college details
                </div>
                <Button
                  text="Add New"
                  width="100px"
                  onClick={() => setShowAddEducationPopup(true)}
                />
              </div>
            </div>
          )}
        </>
      )}
      {isEducationComponent && EducationComponent && (
        <EducationComponent.component
          type={EducationComponent.value}
          title={EducationComponent.label}
          onClose={() => {
            setIsEducationComponent(false);
            setSelectedEducationType(null);
            setEducationComponent(null);
            setShowEditPopup(false);
          }}
          HandleSubmitFunction={editEducation}
          show={showEditPopup}
          editEducationData={editEducationData}
          isEdit={true}
        />
      )}
      <Delete
        show={showDeletePopup}
        onClose={closeDeleteConfirmation}
        text="Are you sure you want to delete this education record?"
        onDelete={() => deleteEducation(deleteEducationId)}
      />
      </div>
    </>
  );
};

export default Education;
