import React, { useState } from "react";
import "./LiveClass.css";
import { IoClose } from "react-icons/io5";
import { BsFillCircleFill } from "react-icons/bs";
import Button from "../../../../../../Components/Profile/Button/Button";
import Swal from 'sweetalert2';

const LiveClass = () => {
  const [otpPopup, setOtpPopup] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [enteredOtp, setEnteredOtp] = useState("");
  const [isLoading] = useState(false);

  // Dummy data - replace with actual API data
  const liveClasses = [
    // {
    //   id: 1,
    //   programName: "Full Stack Development",
    //   pathwayName: "MERN Stack",
    //   moduleName: "Frontend Development",
    //   className: "React Fundamentals",
    //   instructorName: "John Doe",
    //   date: "2024-03-20",
    //   time: "10:00 AM",
    //   duration: "2 hours",
    //   platform: "Google Meet",
    //   otp: "1234",
    //   meetLink: "https://meet.google.com/abc-defg-hij"
    // },
  ];

  const handleJoinNow = (classData) => {
    setSelectedClass(classData);
    setOtpPopup(true);
  };

  const handleOtpSubmit = () => {
    if (enteredOtp === selectedClass.otp) {
      window.open(selectedClass.meetLink, '_blank');
      setOtpPopup(false);
      setEnteredOtp('');
      Swal.fire({
        icon: 'success',
        title: 'Joining live class...',
        timer: 3000,
        showConfirmButton: false
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Invalid OTP',
        text: 'Please try again.',
        timer: 3000,
        showConfirmButton: false
      });
    }
  };

  const handleClickOutside = (e) => {
    if (e.target.classList.contains('lc-otp-overlay')) {
      setOtpPopup(false);
    }
  };

  return (
    <div className="lc-container">
      <div className="lc-header">
        <div className="lc-header-title">Ongoing Live Classes</div>
      </div>

      <div className="lc-body">
        <div className="lc-section">
          {isLoading && (
            <div className="lc-loading">Loading...</div>
          )}

          {!isLoading && liveClasses.length === 0 && (
            <div className="lc-not-found">
              No live classes available at the moment
            </div>
          )}

          {!isLoading && liveClasses.length > 0 && (
            <div className="lc-classes-grid">
              {liveClasses.map((classItem) => (
                <div key={classItem.id} className="lc-class-card">
                  <div className="lc-card-header">
                    <div className="lc-header-content">
                      <div className="lc-program-name">{classItem.programName}</div>
                      <div className="lc-pathway">{classItem.pathwayName}</div>
                    </div>
                    <div className="lc-live-indicator">
                      <BsFillCircleFill className="lc-live-dot" />
                      <span>LIVE NOW</span>
                    </div>
                  </div>
                  
                  <div className="lc-card-content">
                    <div className="lc-detail-grid">
                      <div className="lc-detail-item">
                        <div className="lc-detail-label">Module</div>
                        <div className="lc-detail-value">{classItem.moduleName}</div>
                      </div>
                      <div className="lc-detail-item">
                        <div className="lc-detail-label">Class</div>
                        <div className="lc-detail-value">{classItem.className}</div>
                      </div>
                      <div className="lc-detail-item">
                        <div className="lc-detail-label">Instructor</div>
                        <div className="lc-detail-value">{classItem.instructorName}</div>
                      </div>
                      <div className="lc-detail-item">
                        <div className="lc-detail-label">Date</div>
                        <div className="lc-detail-value">{classItem.date}</div>
                      </div>
                      <div className="lc-detail-item">
                        <div className="lc-detail-label">Time</div>
                        <div className="lc-detail-value">{classItem.time}</div>
                      </div>
                      <div className="lc-detail-item">
                        <div className="lc-detail-label">Duration</div>
                        <div className="lc-detail-value">{classItem.duration}</div>
                      </div>
                      <div className="lc-detail-item">
                        <div className="lc-detail-label">Platform</div>
                        <div className="lc-detail-value">{classItem.platform}</div>
                      </div>
                      <div className="lc-detail-item">
                        <div className="lc-detail-label">OTP</div>
                        <div className="lc-detail-value">{classItem.otp}</div>
                      </div>
                    </div>
                    <p className="lc-instruction">Join the lecture using above OTP</p>
                    <div className="lc-join-btn">
                    <Button
                      text="Join Now"
                      onClick={() => handleJoinNow(classItem)}
                    />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {otpPopup && (
        <div className="lc-otp-overlay" onClick={handleClickOutside}>
          <div className="lc-otp-popup">
            <div className="lc-popup-header">
              <span>Enter OTP</span>
              <IoClose 
                className="lc-close-icon"
                onClick={() => setOtpPopup(false)}
              />
            </div>
            <div className="lc-popup-content">
              <input
                className="lc-otp-input"
                type="text"
                maxLength="4"
                value={enteredOtp}
                onChange={(e) => setEnteredOtp(e.target.value)}
                placeholder="Enter 4-digit OTP"
              />
              <div className="lc-popup-buttons">
                <Button
                  text="Submit"
                  onClick={handleOtpSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LiveClass;
