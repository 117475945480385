import React, { useState, useEffect, useContext } from "react";
import "./LiveClasses.css";
import { LiveClassesSideBarData } from "./Data/SidebarData";
import Sidebar from "../../../../../Components/Profile/Sidebar/Sidebar";
import ProfileDropdown from "../../../../../Components/Profile/ProfileDropdown/ProfileDropdown";
import { ProfileDropdownValues } from "../../../StudentProfile/Data/ProfileDropdownValues";
import { AuthContext } from "../../../../../context/auth-context";
import { useNavigate } from "react-router";
import { useHttpClient } from "../../../../../Components/Hooks/http-hook";
import swal from "sweetalert2";
import Button from "../../../../../Components/Profile/Button/Button";
import NotificationIcon from "../../assets/svgs/NotificationIcon";
import NotificationPopup from "../../Components/NotificationPopup/NotificationPopup";
// import LoadingSpinner from "../../../Components/Input/LoadingSpinner";

const LiveClasses = ({ isNoHeaderFooter, setIsNoHeaderFooter }) => {
  const auth = useContext(AuthContext);
  const { setUserProfilePic, setUserName } = useContext(AuthContext);
  const { sendRequest } = useHttpClient();
  const [ActiveComponent, setActiveComponent] = useState(() => LiveClassesSideBarData[0].component);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    value: "liveClass",
    label: "Live Class",
  });
  // const [profileLoading, setProfileLoading] = useState(true);
  const [selectedSidebarIndex, setSelectedSidebarIndex] = useState(0);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([
    // {
    //   id: 1,
    //   message: "Data science and AI class 1 is live now, Please join the class",
    //   time: "Now",
    //   read: false,
    //   type: "urgent",
    //   actionUrl: "/profile",
    // },
    // {
    //   id: 2,
    //   message: "Class rescheduled to 12th Dec at 10:00 AM",
    //   time: "1 hour ago",
    //   read: false,
    //   type: "info",
    //   actionUrl: "/live-classes",
    // },
    // {
    //   id: 3,
    //   message: "Assignment due date is approaching. Click here to submit assignment",
    //   time: "5 hours ago",
    //   read: false,
    //   type: "reminder",
    //   actionUrl: "/assignments",
    // },
    // {
    //   id: 4,
    //   message: "Your assignment has been reviewed",
    //   time: "5 hours ago",
    //   read: true,
    //   type: "info",
    //   actionUrl: "/assignments",
    // }
  ]);

  const history = useNavigate();

  const renderSidebar = (data) => {
    setActiveComponent(() => data.component);
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
    } catch (_) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/profile/user`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            "Refresh-Token": auth.refresh_token,
            Authorization: auth.token,
          }
        );
        if (responseData.data.name) {
          setUserName(responseData.data.name);
        }
        if (responseData.data.profile_pic_url) {
          if (isValidUrl(responseData.data.profile_pic_url)) {
            setUserProfilePic(responseData.data.profile_pic_url);
          } else {
            setUserProfilePic(
              `${process.env.REACT_APP_BACKEND_URL}/${responseData.data.profile_pic_url}`
            );
          }
        }
        // setProfileLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    fetchProfile();
  }, []);

  useEffect(() => {
    if (selectedOption.value === "resume") {
      history("/resume");
    } else if (selectedOption.value === "profile") {
      history("/profile");
    } else if (selectedOption.value === "referralCode") {
      history("/referral-code");
    } else if (selectedOption.value === "generate-resume") {
      history("/generate-resume");
    } else if (selectedOption.value === "logout") {
      swal
        .fire({
          title: "Logout",
          text: "Are you sure you want to logout?",
          showCancelButton: true,
          confirmButtonText: "Logout",
          confirmButtonColor: "#ff0000",
          cancelButtonText: "Cancel",
          cancelButtonColor: "#2874f0",
          allowOutsideClick: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            auth.logout();
            setIsNoHeaderFooter(false);
          } else {
            setSelectedOption({
              value: "liveClass",
              label: "Live Class",
            });
          }
        });
    } else if (selectedOption.value === "resetPassword") {
      setIsNoHeaderFooter(false);
      history("/password-reset");
    } else {
      renderSidebar(LiveClassesSideBarData[0]);
    }

    setSelectedSidebarIndex(0);
  }, [selectedOption]);

  useEffect(() => {
    setIsNoHeaderFooter(true);
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        isDropdownOpen &&
        !event.target.closest(".profile-content-header-dropdown")
      ) {
        setIsDropdownOpen(false);
      }
      
      if (
        showNotifications &&
        !event.target.closest(".profile-content-header-notification")
      ) {
        setShowNotifications(false);
      }
    };
    
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isDropdownOpen, showNotifications]);

  useEffect(() => {
    renderSidebar(LiveClassesSideBarData[0]);
  }, []);

  const handleMarkAllRead = () => {
    setNotifications(notifications.map(notif => ({ ...notif, read: true })));
  };

  const handleSeeAll = () => {
    // Navigate to notifications page or handle see all logic
    console.log("See all notifications");
    history("/announcements");
  };

  return (
    <>
      {/* {profileLoading ? (
        <div className="profile">
          <LoadingSpinner asOverlay />
        </div>
      ) : (
        <> */}
      <div className="profile">
        <Sidebar
          SidebarData={LiveClassesSideBarData}
          renderSidebar={renderSidebar}
          setIsNoHeaderFooter={setIsNoHeaderFooter}
          selectedSidebarIndex={selectedSidebarIndex}
          setSelectedSidebarIndex={setSelectedSidebarIndex}
        />

        <div className="profile-content">
          <div className="profile-content-header">
            <div className="profile-content-header-live-classes">
              <Button
                text="Live Classes"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                disabled={true}
                width="130px"
              />
            </div>
            {/* notification button icon  */}
            <div className="profile-content-header-notification">
              <div 
                className="notification-trigger"
                onClick={() => setShowNotifications(!showNotifications)}
              >
                <NotificationIcon 
                  hasUnread={notifications.some(notif => !notif.read)} 
                />
              </div>
              {showNotifications && (
                <NotificationPopup
                  notifications={notifications}
                  onMarkAllRead={handleMarkAllRead}
                  onSeeAll={handleSeeAll}
                  onClose={() => setShowNotifications(false)}
                />
              )}
            </div>
            <div className="profile-content-header-dropdown">
              <ProfileDropdown
                ProfileDropdownValues={ProfileDropdownValues}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                isOpen={isDropdownOpen}
                setIsOpen={setIsDropdownOpen}
              />
            </div>
          </div>
          <div className="profile-content-body">
            <div className="live-classes-body-home-button" onClick={() => history("/")}>
              Home
            </div>
            <ActiveComponent setSelectedOption={setSelectedOption} />
          </div>
        </div>
      </div>
      {/* </>
      )} */}
    </>
  );
};

export default LiveClasses;
