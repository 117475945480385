import React, { useState, useEffect, useContext, useRef } from "react";
import Webcam from "react-webcam";
import "./InterviewPlatform.css";
import Button from "../../../../../../../Components/Profile/Button/Button";
import interviewstartimg from "../../../../../../../assets/interviewstartimg.png";
import { AuthContext } from "../../../../../../../context/auth-context";
import NavLegends from "../Components/NavLegends";
import LoadingQuestion from "./LoadingQuestion";
import { useHttpClient } from "../../../../../../../Components/Hooks/http-hook";
import { ToastContainer, toast } from "react-toastify";

const InterviewPlatform = ({ setSelectedTab, questions, examId }) => {
  const auth = useContext(AuthContext);
  const [stream, setStream] = useState(null);
  const [isAccessGranted, setAccessGranted] = useState(false);
  const [error, setError] = useState(null);
  const [selectedQuestionId, setSelectedQuestionId] = useState(1);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [questionSpoken, setQuestionSpoken] = useState(false);
  const [recordButtonDisabled, setRecordButtonDisabled] = useState(true);
  const [displayText, setDisplayText] = useState("");
  const [recording, setRecording] = useState(false);
  const [recognitionResult, setRecognitionResult] = useState("");
  const [timer, setTimer] = useState(20);
  const recordedChunksRef = useRef([]);
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const { isLoading, error: error1, sendRequest, clearError } = useHttpClient();

  const recognition = new window.webkitSpeechRecognition();
  recognition.continuous = true;

  const handleSelectedQuestionId = (id) => {
    setSelectedQuestionId(id);
    setSelectedQuestion(questions && questions.find((data) => data.id === id));
    setQuestionSpoken(false);
    setRecordButtonDisabled(true);
    recordedChunksRef.current = [];
  };

  const handleUserMediaError = (error) => {
    setError(error.message || "Error accessing media devices");
  };

  const handleUserMedia = (stream) => {
    setStream(stream);
    setAccessGranted(true);
  };

  const videoConstraints = {
    width: 300,
    height: 200,
    facingMode: "user",
  };

  const displayTextOneByOne = (text) => {
    let i = 0;
    setDisplayText("");
    let textInterval = setInterval(() => {
      if (i < text.length) {
        setDisplayText(text.slice(0, i + 1));
        i++;
      } else {
        clearInterval(textInterval);
      }
    }, 50);
  };

  const handleStartCaptureClick = () => {
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  };

  const handleDataAvailable = ({ data }) => {
    if (data.size > 0) {
      recordedChunksRef.current = recordedChunksRef.current.concat(data);
    }
  };

  const handleStopCaptureClick = () => {
    mediaRecorderRef.current.stop();
  };

  useEffect(() => {
    if (
      selectedQuestion &&
      !questionSpoken &&
      window.speechSynthesis.getVoices().length > 0 &&
      !isLoading
    ) {
      let utterance = new SpeechSynthesisUtterance(selectedQuestion.question);
      console.log(utterance);
      utterance.voice = window.speechSynthesis
        .getVoices()
        .find(
          (v) =>
            v.name ==
            decodeURIComponent(
              atob(
                "R29vZ2xlJTIwJUUwJUE0JUI5JUUwJUE0JUJGJUUwJUE0JUE4JUUwJUE1JThEJUUwJUE0JUE2JUUwJUE1JTgw"
              )
            )
        );
      utterance.onend = () => {
        setRecordButtonDisabled(false);
      };
      window.speechSynthesis.speak(utterance);
      setQuestionSpoken(true);
      setRecordButtonDisabled(false);
      displayTextOneByOne(selectedQuestion.question);
    }
  }, [selectedQuestion, questionSpoken, window.speechSynthesis.getVoices()]);

  const sendRecordingToServer = (transcript) => {
    const formData = new FormData();
    formData.append("question_id", selectedQuestion.question_id);
    formData.append("exam_id", examId);
    formData.append("answer", transcript);
    formData.append("video_details", new Blob(recordedChunksRef.current, { type: "video/webm" }));

    sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/api/interview_prep/save_interview_answer_v2`,
      "POST",
      formData,
      {
        "Refresh-Token": auth.refresh_token,
        Authorization: auth.token,
      }
    )
      .then((response) => {
        console.log(response);
        if (selectedQuestionId === questions.length) {
          toast.success("Your interview has been submitted successfully");
          setSelectedTab("interviewFeedback");
        }
      })
      .catch((err) => {
        console.log(err);
        if (selectedQuestionId === questions.length) {
          setSelectedTab("interviewFeedback");
        }
      });
  };

  const handleSaveAnswer = () => {
    sendRecordingToServer(recognitionResult);
  };

  const handleEndTest = () => {
    sendRecordingToServer(recognitionResult);
  };

  useEffect(() => {
    let interval;
    if (recording && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
        if (timer === 1) {
          toggleRecording();
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [recording, timer]);

  const toggleRecording = () => {
    if (!recording) {
      setRecording(true);
      recognition.start();
      handleStartCaptureClick();
      setTimer(120);
    } else {
      setRecording(false);
      recognition.stop();
      handleStopCaptureClick();
      if (selectedQuestionId === questions.length) {
        handleEndTest();
      } else {
        handleSaveAnswer();
        handleSelectedQuestionId(selectedQuestionId + 1);
      }
      setTimer(120);
    }
  };

  const onRecordButtonClick = () => {
    if (!isAccessGranted || recordButtonDisabled) return;
    toggleRecording();
  };

  useEffect(() => {
    recognition.onresult = (event) => {
      setRecognitionResult(event.results[0][0].transcript);
    };
  }, [recognition]);

  return (
    <>
      <ToastContainer closeButton={false} />
      {isLoading ? (
        <LoadingQuestion />
      ) : (
        <div className="interview-platform-container">
          <div className="interview-platform-video-container">
            <Webcam
              audio={true}
              muted={true}
              videoConstraints={videoConstraints}
              onUserMediaError={handleUserMediaError}
              onUserMedia={handleUserMedia}
              ref={webcamRef}
            />
            {!stream && (
              <>
                {auth.profile_pic ? (
                  <img
                    className="interview-platform-img"
                    src={auth.profile_pic}
                    alt="Profile"
                  />
                ) : (
                  <img
                    src={interviewstartimg}
                    alt="interviewstartimg"
                    className="interview-platform-img"
                  />
                )}
              </>
            )}
          </div>
          <div className="interview-question-timing">
            Time remaining for this question :
            <div className="interview-question-timing-data">
              {Math.floor(timer / 60)}:{timer % 60 < 10 ? "0" : ""}
              {timer % 60}
            </div>
          </div>
          <div className="interview-platform-nav-legends">
            <NavLegends
              selectedQuestionId={selectedQuestionId}
              setSelectedQuestionId={setSelectedQuestionId}
              questionLength={questions.length}
              questions={questions}
              setSelectedQuestion={setSelectedQuestion}
              setQuestionSpoken={setQuestionSpoken}
              setDisplayText={setDisplayText}
            />
          </div>
          <div className="interview-platform-ques">
            Q {selectedQuestionId && selectedQuestionId}. {displayText}
          </div>
          <div className="interview-platform-exam-btn">
            <Button
              text={
                recording
                  ? selectedQuestionId === questions.length
                    ? "SUBMIT AND VIEW FEEDBACK"
                    : "SAVE ANSWER"
                  : "RECORD ANSWER"
              }
              width="350px"
              disabled={!isAccessGranted || recordButtonDisabled}
              onClick={onRecordButtonClick}
            />
            {error ? (
              <div className="interview-platform-btn-instructions">
                Failed to connect to media devices please check your camera and
                mic access
              </div>
            ) : (
              <>
                {recording ? (
                  <div className="interview-platform-btn-instructions flashing-text">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 1.5C6.225 1.5 1.5 6.225 1.5 12C1.5 17.775 6.225 22.5 12 22.5C17.775 22.5 22.5 17.775 22.5 12C22.5 6.225 17.775 1.5 12 1.5ZM12 16.5C9.525 16.5 7.5 14.475 7.5 12C7.5 9.525 9.525 7.5 12 7.5C14.475 7.5 16.5 9.525 16.5 12C16.5 14.475 14.475 16.5 12 16.5Z"
                        fill="#FA1B00"
                      />
                    </svg>
                    Recording...
                  </div>
                ) : (
                  <div className="interview-platform-btn-instructions">
                    Click on record answer to start answering
                  </div>
                )}
              </>
            )}
          </div>
          <div className="interview-platform-exam-instructions">
            *You can end the session at any point and get feedback for your
            attempted part.
          </div>
        </div>
      )}
    </>
  );
};

export default InterviewPlatform;
