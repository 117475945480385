import React from "react";
import { useState } from "react";
import "./InterviewFeedback.css";
import InterviewAttempted from "./Pages/InterviewAttempted";
import ScoreDashboard from "./Pages/ScoreDashboard";

const InterviewFeedback = ({ setSelectedTab, setSelectedOption }) => {
  const goToAllInterview = () => {
    setSelectedTab("interviewHome");
  };

  const goToHome = () => {
    setSelectedOption({
      value: "skillbox",
      label: "Skillbox",
    });
  };

  const [showScoreDashboard, setShowScoreDashboard] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const handleShowScoreDashboard = (id) => {
    setShowScoreDashboard(true);
    setSelectedId(id);
  };

  const handleShowAllAttempts = () => {
    setShowScoreDashboard(false);
    setSelectedId(null);
  };

  return (
    <div className="interview-feedback-page">
      <div className="interview-feedback-page-nav-menu">
        <div
          onClick={goToHome}
          className="interview-feedback-page-nav-menu-home"
        >
          Home
        </div>
        &nbsp; &gt; &nbsp;
        <div
          onClick={goToAllInterview}
          className="interview-feedback-page-nav-menu-interview-preperation"
        >
          Interview Preperation
        </div>
        {showScoreDashboard && selectedId && (
          <>
            &nbsp; &gt; &nbsp;
            <div
              onClick={handleShowAllAttempts}
              className="interview-feedback-page-nav-menu-interview-preperation"
            >
              All Attempts
            </div>
          </>
        )}
      </div>
      <div className="interview-feedback-container">
        {showScoreDashboard && selectedId ? (
          <ScoreDashboard id={selectedId} />
        ) : (
          <InterviewAttempted
            handleShowScoreDashboard={handleShowScoreDashboard}
          />
        )}
      </div>
    </div>
  );
};

export default InterviewFeedback;
