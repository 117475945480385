import React, { useState, useContext, useEffect } from "react";
import "./InterviewAttempted.css";
import { AuthContext } from "../../../../../../../context/auth-context";
import { useHttpClient } from "../../../../../../../Components/Hooks/http-hook";
import Loading from "../../../../../../../Components/Loading";
import moment from "moment";
import DataTable from "react-data-table-component";
import InterviewFeedbackWrapper from "../Components/InterviewFeedbackWrapper";
import Select from 'react-select';

const FILTER_OPTIONS = [
  { value: 'recent', label: 'Recent' },
  { value: 'roleBased', label: 'Role Based' },
  { value: 'skillBased', label: 'Skill Based' },
  { value: 'profileBased', label: 'Profile Based' },
  { value: 'communicationBased', label: 'Communication Based' },
  { value: 'placementBased', label: 'Placement Based' },
  { value: 'courseBased', label: 'Course Based' }
];

const InterviewAttempted = ({ handleShowScoreDashboard }) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [interviews, setInterviews] = useState([]);
  const [lastUpdated, setLastUpdated] = useState("");
  const [selectedFilter, setSelectedFilter] = useState('recent');

  // useEffect(() => {
  //   const fetchInterviews = async () => {
  //     try {
  //       const responseData = await sendRequest(
  //         `${process.env.REACT_APP_BACKEND_URL}/api/interview_prep/get_interview_exam_attempts/recent`,
  //         "GET",
  //         null,
  //         {
  //           "Content-Type": "application/json",
  //           "Refresh-Token": auth.refresh_token,
  //           Authorization: auth.token,
  //         }
  //       );
  //       if (
  //         responseData.exams &&
  //         responseData.exams.length > 0 &&
  //         responseData.last_updated
  //       ) {
  //         setInterviews(responseData.exams);
  //         setLastUpdated(changeDateFormat(responseData.last_updated));
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   fetchInterviews();
  // }, []);

  const changeDateFormat = (date) => {
    return moment(date).format("DD/MM/YYYY");
  };

  const handleFilterChange = (selectedOption) => {
    setSelectedFilter(selectedOption.value);
    fetchInterviewData(selectedOption.value);
  };

  const fetchInterviewData = async (filterType) => {
    try {
      clearError();
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/interview_prep/get_interview_exam_attempts/${filterType}`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      if (
        responseData.exams &&
        responseData.exams.length > 0 &&
        responseData.last_updated
      ) {
        setInterviews(responseData.exams);
        setLastUpdated(changeDateFormat(responseData.last_updated));
      } else {
        setInterviews([]);
        setLastUpdated('');
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchInterviewData(selectedFilter);
  }, []);

  useEffect(() => {
    return () => {
      clearError();
    };
  }, [clearError]);

  const getColumns = (filterType) => {
    const commonColumns = {
      attemptNumber: {
        name: "Attempt No.",
        selector: (row) => row.attempt_number,
        sortable: true,
        width: "200px",
        center: true,
      },
      score: {
        name: "Marks",
        selector: (row) => `${row.obtained_score} %`,
        sortable: true,
        width: "150px",
        center: true,
      }
    };

    switch (filterType) {
      case 'recent':
        return [
          {
            name: "Exam Type",
            selector: (row) => row.exam_type,
            sortable: true,
          },
          {
            name: "Level",
            selector: (row) => row.level || "NA",
            sortable: true,
            width: "100px",
            center: true,
          },
          commonColumns.attemptNumber,
          commonColumns.score
        ];

      case 'roleBased':
        return [
          {
            name: "Job Role",
            selector: (row) => row.role,
            sortable: true,
          },
          {
            name: "Job Level",
            selector: (row) => row.level || "NA",
            sortable: true,
            width: "150px",
            center: true,
          },
          commonColumns.attemptNumber,
          commonColumns.score
        ];

      case 'skillBased':
        return [
          {
            name: "Skill",
            selector: (row) => row.skill,
            sortable: true,
          },
          {
            name: "Level",
            selector: (row) => row.level || "NA",
            sortable: true,
            width: "100px",
            center: true,
          },
          commonColumns.attemptNumber,
          commonColumns.score
        ];

      case 'placementBased':
        return [
          {
            name: "Company Package",
            selector: (row) => row.companypackage,
            sortable: true,
          },
          commonColumns.attemptNumber,
          commonColumns.score
        ];
      
      case 'courseBased':
        return [
          {
            name: "Course",
            selector: (row) => row.course,
            sortable: true,
          },
          commonColumns.attemptNumber,
          commonColumns.score
        ];

      default:
        return [
          {
            name: "Exam Type",
            selector: (row) => row.exam_type,
            sortable: true,
          },
          {
            name: "Level",
            selector: (row) => row.level || "NA",
            sortable: true,
            width: "100px",
            center: true,
          },
          commonColumns.attemptNumber,
          commonColumns.score
        ];
    }
  };

  const columns = getColumns(selectedFilter);

  return (
    <div>
      <div className="interview-feedback-header">
        <div className="interview-feedback-header-title">
          Interview Feedback
        </div>
        <div className="interview-feedback-filter-section">
          <Select
            value={FILTER_OPTIONS.find(option => option.value === selectedFilter)}
            onChange={handleFilterChange}
            options={FILTER_OPTIONS}
            className="interview-feedback-filter"
            placeholder="Select Filter"
            isSearchable={false}
          />
          {/* <div className="interview-feedback-body-last-updated">
            Last Updated: {lastUpdated}
          </div> */}
        </div>
      </div>
      {isLoading && (
        <InterviewFeedbackWrapper>
          <Loading
            color="#004c73"
            customClass="interview-feedback-interview-loading"
          />
        </InterviewFeedbackWrapper>
      )}
      {error && (
        <InterviewFeedbackWrapper>
          <div className="interview-questions-not-found-section">
            Something went wrong, Please try again later
          </div>
        </InterviewFeedbackWrapper>
      )}

      {!isLoading && !error && (
        <InterviewFeedbackWrapper>
          <DataTable
            columns={columns}
            data={interviews}
            pagination
            noDataComponent={
              <div className="interview-questions-not-found-section-table">
                No Interview Attempted for this filter type. 
              </div>
            }
            customStyles={{
              table: {
                style: {
                  maxHeight: "600px",
                  overflowY: "auto",
                },
              },
              rows: {
                style: {
                  minHeight: "48px",
                },
              },
              headCells: {
                style: {
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#004c73",
                },
              },
              cells: {
                style: {
                  fontSize: "14px",
                },
              },
              pagination: {
                style: {
                  marginTop: "20px",
                  justifyContent: "flex-start",
                  border: "none",
                  backgroundColor: "none",
                },
                pageButtonsStyle: {
                  backgroundColor: "#fff",
                  borderRadius: "5px",
                  margin: "0px",
                  marginRight: "5px",
                  border: "1px solid #004C73",
                  cursor: "pointer",
                  svg: {
                    fill: "#004C73",
                  },
                  "&:hover": {
                    backgroundColor: "#D2F0FF",
                    svg: {
                      fill: "#004C73",
                    },
                  },
                  "&:disabled": {
                    backgroundColor: "#fff",
                    border: "1px solid #ccc",
                    svg: {
                      fill: "#ccc",
                    },
                    "&:hover": {
                      backgroundColor: "#fff",
                    },
                  },
                  "&:active": {
                    backgroundColor: "#D2F0FF",
                    svg: {
                      fill: "#fff",
                    },
                  },
                },
              },
            }}
            onRowClicked={(row) => handleShowScoreDashboard(row.exam_id)}
            pointerOnHover
            highlightOnHover
          />
        </InterviewFeedbackWrapper>
      )}
    </div>
  );
};
export default InterviewAttempted;
