import React, { useState } from 'react';
import Info from '../../Components/Info';
import Roles from './pages/Roles';
import './RoleBasedInterview.css';
import SelectLevel from './pages/SelectLevel';
const swal = require("sweetalert2");

const RoleBasedInterview = ({ selectedInterviewType, startInterviewHandler, handleGoToHome, handleGoToAllInterview }) => {
  const [currentPage, setCurrentPage] = useState('info');
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");

  const handleShowRoles = () => {
    setCurrentPage('roles');
  };

  const handleShowSelectLevel = () => {
    setCurrentPage('selectLevel');
  };

  return (
    <>
      {currentPage === 'info' && (
        <Info
          selectedInterviewType={selectedInterviewType}
          handleGoToHome={handleGoToHome}
          handleGoToAllInterview={handleGoToAllInterview}
          onNext={handleShowRoles}
        />
      )}
      {currentPage === 'roles' && (
        <Roles
          selectedInterviewType={selectedInterviewType}
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
          handleGoToHome={handleGoToHome}
          handleGoToAllInterview={handleGoToAllInterview}
          handleGoToRoleBased={() => setCurrentPage('info')}
          onNext={handleShowSelectLevel}
        />
      )}
      {currentPage === 'selectLevel' && (
        <SelectLevel
          selectedInterviewType={selectedInterviewType}
          startInterviewHandler={startInterviewHandler}
          selectedLevel={selectedLevel}
          setSelectedLevel={setSelectedLevel}
          selectedRole={selectedRole}
          handleGoToHome={handleGoToHome}
          handleGoToAllInterview={handleGoToAllInterview}
          handleGoToRoles={() => setCurrentPage('info')}
          handleGoToSelectRole={() => setCurrentPage('roles')}
        />
      )}
    </>
  );
};

export default RoleBasedInterview;
