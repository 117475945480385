import React from 'react';
import './CertificateViewPopup.css';
import { FaDownload, FaLinkedin, FaTimes } from 'react-icons/fa';

const CertificateViewPopup = ({ certificateUrl, onClose, onDownload, onShareLinkedIn }) => {
    return (
        <div className="certificate-view-popup">
            <div className="certificate-view-popup__overlay" onClick={onClose} />
            <div className="certificate-view-popup__content">
                <button 
                    className="certificate-view-popup__close" 
                    onClick={onClose}
                    aria-label="Close popup"
                >
                    <FaTimes />
                </button>
                
                <div className="certificate-view-popup__image-container">
                    <img 
                        src={certificateUrl} 
                        alt="Certificate" 
                        className="certificate-view-popup__image"
                    />
                </div>

                <div className="certificate-view-popup__actions">
                    <button 
                        className="certificate-view-popup__action-btn" 
                        onClick={onDownload}
                    >
                        <FaDownload />
                        <span>Download Certificate</span>
                    </button>
                    <button 
                        className="certificate-view-popup__action-btn" 
                        onClick={onShareLinkedIn}
                    >
                        <FaLinkedin />
                        <span>Post on LinkedIn</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CertificateViewPopup;
