import React, { useState, useEffect, useContext } from "react";
import "./Certifications.css";
import Button from "../../../../Components/Profile/Button/Button";
import CertificationForm from "../forms/CertificationForm";
import { useHttpClient } from "../../../../Components/Hooks/http-hook";
import { AuthContext } from "../../../../context/auth-context";
import Loading from "../../../../Components/Loading";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import Delete from "../Popups/Delete";
import { useNavigate } from "react-router";

const Certifications = () => {
  const history = useNavigate();
  const auth = useContext(AuthContext);
  const { sendRequest, error: requestError } = useHttpClient();
  const [certificationsList, setCertificationsList] = useState([]);
  const [showCertificationForm, setShowCertificationForm] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteCertificationId, setDeleteCertificationId] = useState(null);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editCertificationData, setEditCertificationData] = useState(null);

  const showDeleteConfirmation = (id) => {
    setDeleteCertificationId(id);
    setShowDeletePopup(true);
  };

  const closeDeleteConfirmation = () => {
    setDeleteCertificationId(null);
    setShowDeletePopup(false);
  };

  const showEditCertificationPopup = (data) => {
    setEditCertificationData(data);
    setShowEditPopup(true);
  };

  const changeCertificationDateFormat = (date) => {
    const dateArray = date.split("-");
    const year = dateArray[0];
    const month = dateArray[1];
    const day = dateArray[2];
    const newDate = moment(`${year}-${month}-${day}`).format("DD MMM YYYY");
    return newDate;
  };

  const deleteCertification = async (id) => {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/profile/certification/${id}`,
      "DELETE",
      null,
      {
        "Content-Type": "application/json",
        "Refresh-Token": auth.refresh_token,
        Authorization: auth.token,
      }
    );
    if (responseData && responseData.message) {
      toast(responseData.message, {
        autoClose: 3000,
        type: toast.TYPE.SUCCESS,
      });
      setShowDeletePopup(false);
      const updatedCertificationsList = certificationsList.filter(
        (item) => item.id !== id
      );
      setCertificationsList(updatedCertificationsList);
    }
  };

  const changeTimestampToDaysAgo = (timestamp) => {
    const date = moment(timestamp);
    const currentDate = moment();
    const daysAgo = currentDate.diff(date, "days");
    if (daysAgo === 0) {
      return "Updated today";
    } else if (daysAgo > 30) {
      const monthsAgo = currentDate.diff(date, "months");
      return `${monthsAgo} months ago`;
    } else {
      return `${daysAgo} days ago`;
    }
  };

  const addNewCertification = async (data) => {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/profile/certification`,
      "POST",
      JSON.stringify(data),
      {
        "Content-Type": "application/json",
        "Refresh-Token": auth.refresh_token,
        Authorization: auth.token,
      }
    );
    if (responseData && responseData.data) {
      toast(responseData.message, {
        autoClose: 3000,
        type: toast.TYPE.SUCCESS,
      });
      setShowCertificationForm(false);
      const updatedCertificationList = [
        ...certificationsList,
        {
          ...responseData.data,
          created_at: "Just now",
        },
      ];
      setCertificationsList(updatedCertificationList);
    }
  };

  const editCertification = async (data) => {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/profile/certification/${data.id}`,
      "PUT",
      JSON.stringify(data),
      {
        "Content-Type": "application/json",
        "Refresh-Token": auth.refresh_token,
        Authorization: auth.token,
      }
    );
    if (responseData && responseData.data) {
      toast(responseData.message, {
        autoClose: 3000,
        type: toast.TYPE.SUCCESS,
      });
      setShowEditPopup(false);
      const updatedCertificationList = certificationsList.map((item) =>
        item.id === data.id
          ? { ...responseData.data, created_at: "Just Now" }
          : item
      );
      setCertificationsList(updatedCertificationList);
    }
  };

  useEffect(() => {
    const getCertificationsDetails = async () => {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/profile/certifications`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      if (responseData && responseData.data) {
        setCertificationsList(
          responseData.data.map((item) => ({
            ...item,
            created_at: changeTimestampToDaysAgo(item.update_timestamp),
          }))
        );
      }
      setPageLoading(false);
    };
    getCertificationsDetails();
  }, []);

  useEffect(() => {
    if (requestError) {
      toast(requestError, {
        autoClose: 3000,
        type: toast.TYPE.ERROR,
      });
      setPageLoading(false);
    }
  }, [requestError]);

  return (
    <>
    <div
      className="stu-skillbox-nav-header"
      onClick={() => history("/skillbox-v2")}
    >
      Home
    </div>
    <div className="profile-certifications-page">
      <div className="profile-certifications-header">
        <div className="profile-certifications-header-title">
          My Profile - Certifications
        </div>
        {certificationsList && certificationsList.length > 0 && (
          <Button
            text="Add New"
            width="100px"
            onClick={() => setShowCertificationForm(true)}
          />
        )}
      </div>
      {pageLoading ? (
        <div className="profile-loader-container">
          <div className="profile-loader-sub-container">
            <Loading
              color="#14144e"
              customClass="profile-loader-container-for-loader"
            />
          </div>
        </div>
      ) : (
        <>
          <CertificationForm
            title="Certification/ Course Details"
            onClose={() => setShowCertificationForm(false)}
            show={showCertificationForm}
            HandleSubmitFunction={addNewCertification}
            setShowAddExperienceForm={setShowCertificationForm}
          />
          <ToastContainer closeButton={false} />
          {certificationsList && certificationsList.length > 0 ? (
            <div className="profile-certifications-section-1">
              {certificationsList.map((certification) => (
                <div
                  className="profile-certifications-card"
                  key={certification.id}
                >
                  <div className="profile-certifications-card-header">
                    <div className="profile-certifications-card-header-section-1">
                      <div className="profile-certifications-card-header-title">
                        {certification.title} - {certification.issuer}
                      </div>
                    </div>
                    <div className="profile-certifications-card-header-section-2">
                      <div className="profile-certifications-card-body-title-1">
                        {certification.created_at}
                      </div>
                    </div>
                  </div>
                  <div className="profile-certifications-card-body">
                    <div className="profile-certifications-card-body-container-1">
                      <div className="profile-certifications-card-body-container-1-section-1">
                        <div className="profile-certifications-card-body-title">
                          Start Date
                        </div>
                        <div className="profile-certifications-card-body-data-value">
                          {certification.start_date ? changeCertificationDateFormat(certification.start_date):""}
                        </div>
                      </div>
                      <div className="profile-certifications-card-body-container-1-section-2">
                        <div className="profile-certifications-card-body-title">
                          End Date
                        </div>
                        <div className="profile-certifications-card-body-data-value">
                        {certification.end_date ? changeCertificationDateFormat(certification.end_date):""}
                        </div>
                      </div>
                      <div className="profile-certifications-card-body-container-1-section-3">
                        <div className="profile-certifications-card-body-title">
                          Location
                        </div>
                        <div className="profile-certifications-card-body-data-value">
                          {certification.issuer}
                        </div>
                      </div>
                    </div>
                    {certification.certification_url && (
                      <div className="profile-certifications-card-body-container-2">
                        <div className="profile-certifications-card-body-title">
                          Certification URL
                        </div>
                        <div className="profile-project-card-body-data-value profile-project-card-body-link">
                          <a
                            href={certification.certification_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {certification.certification_url}
                          </a>
                        </div>
                      </div>
                    )}
                    <div className="profile-certifications-card-body-container-2">
                      <div className="profile-certifications-card-body-title">
                        Description
                      </div>
                      <div className="profile-certifications-card-body-data-value">
                        {certification.description}
                      </div>
                    </div>
                  </div>
                  <div className="profile-certifications-card-footer">
                    <div className="profile-certifications-card-edit">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.41421 15.8895L16.5563 5.74738L15.1421 4.33316L5 14.4753V15.8895H6.41421ZM7.24264 17.8895H3V13.6468L14.435 2.21184C14.8256 1.82132 15.4587 1.82132 15.8492 2.21184L18.6777 5.04027C19.0682 5.43079 19.0682 6.06395 18.6777 6.45447L7.24264 17.8895ZM3 19.8895H21V21.8895H3V19.8895Z"
                          fill="#004C73"
                        />
                      </svg>
                      <div className="profile-certifications-card-edit-title"
                        onClick={() => showEditCertificationPopup(certification)}
                      >
                        Edit Details
                      </div>
                    </div>
                    <div
                      className="profile-certifications-card-delete"
                      onClick={() => showDeleteConfirmation(certification.id)}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7 4V2H17V4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7ZM6 6V20H18V6H6ZM9 9H11V17H9V9ZM13 9H15V17H13V9Z"
                          fill="#004C73"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="profile-certifications-section-2">
              <div className="profile-certifications-add-new">
                <div className="profile-certifications-add-new-title">
                  Add Certificate/ Course Details
                </div>
                <div className="profile-certifications-add-new-subtitle">
                  Your certifications/ courses details
                </div>
                <Button
                  text="Add New"
                  width="100px"
                  onClick={() => setShowCertificationForm(true)}
                />
              </div>
            </div>
          )}
        </>
      )}
      <CertificationForm
        title="Edit Certification Details"
        onClose={() => {
          setShowEditPopup(false);
        }}
        show={showEditPopup}
        HandleSubmitFunction={editCertification}
        setShowAddExperienceForm={setShowEditPopup}
        editCertificationData={editCertificationData}
        isEdit={true}
      />
      <Delete
        show={showDeletePopup}
        text="Are you sure you want to delete this certification?"
        onClose={closeDeleteConfirmation}
        onDelete={() => deleteCertification(deleteCertificationId)}
      />
      </div>
    </>
  );
};

export default Certifications;
