import React, { useState, useEffect, useRef } from "react";
import "./Resume.css";
import Button from "../../../../Components/Profile/Button/Button";
import { useNavigate } from "react-router";

const GenerateResume = () => {
  const history = useNavigate();
  return (
    <>
      <div
        className="stu-skillbox-nav-header"
        onClick={() => history("/skillbox-v2")}
      >
        Home
      </div>
      <div className="profile-user-resume-page">
        {/* <div className="profile-content-header-nav-menu">Home</div> */}
        <div className="profile-user-resume-header">
          <div className="profile-user-resume-header-title">
            My Resume - Generate Resume
          </div>
          <Button text="Generate New" width="120px" disabled={true} />
        </div>
        <div className="profile-user-resume">
          {/* {fileData ? (
          <div className="profile-user-resume__view">
            <div className="profile-user-resume__view__details">
              <div className="profile-user-resume-view-details-section-1">
                <span>File Name:</span>
                {fileData.fileName}
              </div>
              <div className="profile-user-resume-view-details-section-2">
                <span>Uploaded At:</span>
                {fileData.uploadedAt}
              </div>
              <div className="profile-user-resume-view-details-section-3">
                <div className="profile-user-resume-download-btn">
                  <FaDownload />
                </div>
              </div>
            </div>
            <div className="profile-user-resume__view__details__download">
              {renderFile()}
            </div>
          </div>
        ) : ( */}
          <div className="profile-user-resume__view__no-resume">
            This section is under development. Please check back later.
          </div>
          {/* )} */}
        </div>
      </div>
    </>
  );
};

export default GenerateResume;
