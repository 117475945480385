import React, { useState, useEffect } from "react";
import "./Profile.css";
import { ProfileSideBarData } from "./Data/SidebarData";
import Navbar from "../../../Components/SkillboxNavbar/Navbar";
import { ProfileDropdownValues } from "../StudentProfile/Data/ProfileDropdownValues";
import Sidebar from "../../../Components/Profile/Sidebar/Sidebar";
import { useNavigate } from "react-router";

const Profile = ({ isNoHeaderFooter, setIsNoHeaderFooter }) => {
  const [sidebarComponent, setSidebarComponent] = useState(
    ProfileSideBarData[0].component
  );

  const [selectedSidebarIndex, setSelectedSidebarIndex] = useState(0);

  const history = useNavigate();

  const renderSidebar = (data) => {
    setSidebarComponent(data.component);
  };

  useEffect(() => {
    renderSidebar(ProfileSideBarData[0]);
  }, []);

  return (
    <>
      <div className="profile">
        <Sidebar
          SidebarData={ProfileSideBarData}
          renderSidebar={renderSidebar}
          setIsNoHeaderFooter={setIsNoHeaderFooter}
          selectedSidebarIndex={selectedSidebarIndex}
          setSelectedSidebarIndex={setSelectedSidebarIndex}
        />
        <div className="profile-content">
          <div className="profile-content-header">
            <Navbar
              setIsNoHeaderFooter={setIsNoHeaderFooter}
              setSelectedSidebarIndex={setSelectedSidebarIndex}
              currentOptionIndex={0}
              dropdownValues={ProfileDropdownValues}
            />
          </div>
          <div className="profile-content-body">{sidebarComponent}</div>
        </div>
      </div>
    </>
  );
};

export default Profile;
