import React, { useState } from 'react';
import './AnnouncementContent.css';
import { FaBell, FaInfoCircle, FaExclamationTriangle, FaClock } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const AnnouncementContent = () => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([
    {
      id: 1,
      title: "Data Science and AI Class 1",
      message: "Class is live now. Please join the session.",
      time: "2024-02-08T10:30:00",
      read: false,
      type: "urgent",
      actionUrl: "/live-classes",
      author: "Dr. Sarah Johnson",
      role: "Mentor"
    },
    {
      id: 2,
      title: "Class Rescheduled",
      message: "Class rescheduled to 12th Dec at 10:00 AM",
      time: "2024-02-08T10:00:00",
      read: false,
      type: "info",
      actionUrl: "/live-classes",
      author: "Dr. Sarah Johnson",
      role: "Mentor"
    },
    {
      id: 3,
      title: "Assignment Due Date",
      message: "Assignment due date is approaching. Click here to submit assignment",
      time: "2024-02-08T09:00:00",
      read: false,
      type: "reminder",
      actionUrl: "/assignments",
      author: "Dr. Sarah Johnson",
      role: "Mentor"
    },
    {
      id: 4,
      title: "Assignment Reviewed",
      message: "Your assignment has been reviewed",
      time: "2024-02-08T09:00:00",
      read: true,
      type: "info",
      actionUrl: "/skillbox-v2",
      author: "Dr. Sarah Johnson",
      role: "Mentor"
    },
    {
      id: 5,
      title: "Assignment Reviewed",
      message: "Your assignment has been reviewed",
      time: "2024-02-08T09:00:00",
      read: true,
      type: "info",
      actionUrl: "/profile",
      author: "",
      role: "System"
    }
  ]);

  const getNotificationIcon = (type) => {
    const iconClass = `announcement-icon ${type}`;
    switch (type) {
      case 'urgent':
        return <FaExclamationTriangle className={iconClass} />;
      case 'info':
        return <FaInfoCircle className={iconClass} />;
      case 'reminder':
        return <FaClock className={iconClass} />;
      default:
        return <FaBell className={iconClass} />;
    }
  };

  const handleMarkAllRead = () => {
    setNotifications(notifications.map(notif => ({ ...notif, read: true })));
  };

  const unreadCount = notifications.filter(notif => !notif.read).length;

  const handleNotificationClick = (actionUrl) => {
    navigate(actionUrl);
  };

  const renderAnnouncementCard = (notification) => (
    <div 
      key={notification.id}
      className={`announcement-card ${!notification.read ? 'announcement-card--unread' : ''} announcement-card--${notification.type}`}
      onClick={() => handleNotificationClick(notification.actionUrl)}
      role="button"
      tabIndex={0}
    >
      <div className="announcement-card-header">
        {getNotificationIcon(notification.type)}
        <div className="announcement-card-title">{notification.title}</div>
      </div>
      <div className="announcement-card-message">{notification.message}</div>
      <div className="announcement-card-footer">
        <div className="announcement-card-author-info">
          <span className="announcement-card-author-role">{notification.role}</span>
          {notification.role !== "System" && (
            <span className="announcement-card-author-name">{notification.author}</span>
          )}
        </div>
        <time className="announcement-card-timestamp">
          {moment(notification.time).fromNow()}
        </time>
      </div>
    </div>
  );

  return (
    <div className="announcements-container">
      {unreadCount > 0 && (
        <div className="announcements-section new-announcements">
          <div className="announcements-header">
            <div className="announcements-header__left">
              <div className="announcements-header__title">New Announcements</div>
              <div className="announcements-count-badge">
                {unreadCount} new announcements
              </div>
            </div>
            <div 
              className="announcements-header__mark-as-read-btn"
              onClick={handleMarkAllRead}
            >
              Mark all as read
            </div>
          </div>
          
          <div className="announcements-list">
            {notifications
              .filter(notif => !notif.read)
              .map(renderAnnouncementCard)}
          </div>
        </div>
      )}

      <div className="announcements-section">
        <h2>This Week's Announcements</h2>
        <div className="announcements-list">
          {notifications
            .filter(notif => notif.read)
            .map(renderAnnouncementCard)}
        </div>
      </div>
    </div>
  );
};

export default AnnouncementContent;
