import React, { useState, useEffect } from "react";
import Webcam from "react-webcam";
import "./InterviewStart.css";
import Button from "../../../../../../../Components/Profile/Button/Button";
import interviewstartimg from "../../../../../../../assets/interviewstartimg.png";

const InterviewStart = ({
  totalNoOfQuestions,
  setShowInterviewBestOfLuckPopup,
  setShowInterviewStartPopup,
  onRetry,
  isRetrying,
  isError
}) => {
  const [stream, setStream] = useState(null);
  const [isAccessGranted, setAccessGranted] = useState(false);
  const [error, setError] = useState(null);

  const handleUserMediaError = (error) => {
    setError(error.message || "Error accessing media devices");
  };

  const handleUserMedia = (stream) => {
    setStream(stream);
    setAccessGranted(true);
  };

  const videoConstraints = {
    width: 300,
    height: 200,
    facingMode: "user",
  };

  return (
    <div className="interview-start-container">
      {(!isError) ? (
        <>
          <div className="interview-start-video-container">
            <Webcam
              audio={true}
              muted={true}
              videoConstraints={videoConstraints}
              onUserMediaError={handleUserMediaError}
              onUserMedia={handleUserMedia}
            />
            {!stream && (
              <img
                src={interviewstartimg}
                alt="interviewstartimg"
                className="interview-start-img"
              />
            )}
          </div>
          <div className="interview-start-details">
            {isAccessGranted ? (
              <>
                <div className="interview-start-media-connected">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 14C13.66 14 15 12.66 15 11V5C15 3.34 13.66 2 12 2C10.34 2 8.99999 3.34 8.99999 5V11C8.99999 12.66 10.34 14 12 14ZM17.91 11C17.42 11 17.01 11.36 16.93 11.85C16.52 14.2 14.47 16 12 16C9.52999 16 7.47999 14.2 7.06999 11.85C7.03472 11.6148 6.91661 11.3999 6.73693 11.2441C6.55725 11.0882 6.32783 11.0017 6.08999 11C5.47999 11 4.99999 11.54 5.08999 12.14C5.57999 15.14 7.97999 17.49 11 17.92V20C11 20.55 11.45 21 12 21C12.55 21 13 20.55 13 20V17.92C14.4715 17.7098 15.8378 17.0361 16.9005 15.9968C17.9632 14.9574 18.6671 13.6065 18.91 12.14C19.01 11.54 18.52 11 17.91 11Z"
                      fill="#00AD50"
                    />
                  </svg>
                  Connected
                </div>
                <div className="interview-start-media-connected">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 10.5V7C17 6.73478 16.8946 6.48043 16.7071 6.29289C16.5196 6.10536 16.2652 6 16 6H4C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7V17C3 17.2652 3.10536 17.5196 3.29289 17.7071C3.48043 17.8946 3.73478 18 4 18H16C16.2652 18 16.5196 17.8946 16.7071 17.7071C16.8946 17.5196 17 17.2652 17 17V13.5L21 17.5V6.5L17 10.5Z"
                      fill="#00AD50"
                    />
                  </svg>
                  Connected
                </div>
              </>
            ) : (
              <>
                {!error && (
                  <div className="interview-start-media-instruction">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#FFA500"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <circle cx="12" cy="12" r="10" />
                      <line x1="12" y1="8" x2="12" y2="12" />
                      <line x1="12" y1="16" x2="12" y2="16" />
                    </svg>
                    Please allow access to your camera and microphone
                  </div>
                )}
              </>
            )}

            {error && (
              <div className="interview-start-media-not-connected">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 17C12.2833 17 12.521 16.904 12.713 16.712C12.905 16.52 13.0007 16.2827 13 16C13 15.7167 12.904 15.4793 12.712 15.288C12.52 15.0967 12.2827 15.0007 12 15C11.7167 15 11.4793 15.096 11.288 15.288C11.0967 15.48 11.0007 15.7173 11 16C11 16.2833 11.096 16.521 11.288 16.713C11.48 16.905 11.7173 17.0007 12 17ZM11 13H13V7H11V13ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22Z"
                  fill="#FA1B00"
                />
              </svg>
              Failed to connect to media devices please check your camera and mic
              access and try again after some time.
            </div>
          )}
        </div>
        {/* <div className="interview-start-total-ques">
          Total Questions: {totalNoOfQuestions}
        </div> */}
        <div className="interview-start-start-exam-btn">
          <Button
            text="START INTERVIEW"
            width="350px"
            disabled={!isAccessGranted}
            onClick={() => {
              setShowInterviewBestOfLuckPopup(true);
              setShowInterviewStartPopup(false);
            }}
          />
        </div>
        <div className="interview-start-exam-instructions">
          Please sit in a well-lit and noise-free environment
          </div>
        </>
      ) : (
        <div className="interview-start-error-container">
          <div className="interview-start-error-message">
            The exam creation failed due to some technical issues. Please try again.
          </div>
          <div className="interview-start-retry-btn">
            <Button
              text={isRetrying ? "RETRYING..." : "RETRY"}
              width="350px"
              onClick={onRetry}
              disabled={isRetrying}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default InterviewStart;
