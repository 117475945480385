import React, { useState, useContext, useEffect } from "react";
import "./Experience.css";
import Button from "../../../../Components/Profile/Button/Button";
import WorkExperienceForm from "../forms/WorkExperienceForm";
import { useHttpClient } from "../../../../Components/Hooks/http-hook";
import { AuthContext } from "../../../../context/auth-context";
import Loading from "../../../../Components/Loading";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import Delete from "../Popups/Delete";
import { WorkType } from "../Data/SelectData";
import { useNavigate } from "react-router";

const Experience = () => {
  const history = useNavigate();
  const [experienceList, setExperienceList] = useState([]);
  const auth = useContext(AuthContext);
  const { sendRequest, error: requestError } = useHttpClient();
  const [showWorkExperienceForm, setShowWorkExperienceForm] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteWorkId, setDeleteWorkId] = useState(null);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editWorkExperienceData, setEditWorkExperienceData] = useState(null);

  const showDeleteConfirmation = (id) => {
    setDeleteWorkId(id);
    setShowDeletePopup(true);
  };

  const closeDeleteConfirmation = () => {
    setDeleteWorkId(null);
    setShowDeletePopup(false);
  };

  const showEditExperiencePopup = (data) => {
    setEditWorkExperienceData(data);
    setShowEditPopup(true);
  };

  const changeWorkDateFormat = (date) => {
    const dateArray = date.split("-");
    const year = dateArray[0];
    const month = dateArray[1];
    const day = dateArray[2];
    const newDate = moment(`${year}-${month}-${day}`).format("DD MMM YYYY");
    return newDate;
  };

  const changeTimestampToDaysAgo = (timestamp) => {
    const date = moment(timestamp);
    const currentDate = moment();
    const daysAgo = currentDate.diff(date, "days");
    if (daysAgo === 0) {
      return "Updated today";
    } else if (daysAgo > 30) {
      const monthsAgo = currentDate.diff(date, "months");
      return `${monthsAgo} months ago`;
    } else {
      return `${daysAgo} days ago`;
    }
  };

  const getWorkTypeLabel = (value) => {
    let label = "";
    WorkType.forEach((item) => {
      if (item.value === value) {
        label = item.label;
      }
    });
    return label;
  };

  const deleteWorkExperience = async (id) => {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/profile/experience/${id}`,
      "DELETE",
      null,
      {
        "Content-Type": "application/json",
        "Refresh-Token": auth.refresh_token,
        Authorization: auth.token,
      }
    );
    if (responseData && responseData.message) {
      toast(responseData.message, {
        autoClose: 3000,
        type: toast.TYPE.SUCCESS,
      });
      setShowDeletePopup(false);
      const updatedExperienceList = experienceList.filter(
        (item) => item.id !== id
      );
      setExperienceList(updatedExperienceList);
    }
  };

  const addNewWorkExperience = async (data) => {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/profile/experience`,
      "POST",
      JSON.stringify(data),
      {
        "Content-Type": "application/json",
        "Refresh-Token": auth.refresh_token,
        Authorization: auth.token,
      }
    );
    if (responseData && responseData.data) {
      toast(responseData.message, {
        autoClose: 3000,
        type: toast.TYPE.SUCCESS,
      });
      setShowWorkExperienceForm(false);
      const updatedExperienceList = [
        ...experienceList,
        {
          ...responseData.data,
          duration: calculateDuration(
            responseData.data.start_date,
            responseData.data.end_date
          ),
          created_at: "Just now",
        },
      ];
      setExperienceList(updatedExperienceList);
    }
  };

  const editWorkExperience = async (data) => {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/profile/experience/${data.id}`,
      "PUT",
      JSON.stringify(data),
      {
        "Content-Type": "application/json",
        "Refresh-Token": auth.refresh_token,
        Authorization: auth.token,
      }
    );
    if (responseData && responseData.data) {
      toast(responseData.message, {
        autoClose: 3000,
        type: toast.TYPE.SUCCESS,
      });
      setShowEditPopup(false);
      const updatedExperienceList = experienceList.map((item) =>
        item.id === data.id
          ? {
              ...responseData.data,
              duration: calculateDuration(
                responseData.data.start_date,
                responseData.data.end_date
              ),
              created_at: "Just Now",
            }
          : item
      );
      setExperienceList(updatedExperienceList);
    }
  };

  const calculateDuration = (startDate, endDate) => {
    const start = moment(startDate, "YYYY-MM-DD");
    const end =
      endDate === "Present" ? moment() : moment(endDate, "YYYY-MM-DD");

    const duration = moment.duration(end.diff(start));
    const years = duration.years();
    const months = duration.months();

    let durationString = "";
    if (years > 0) {
      durationString += `${years} year${years > 1 ? "s" : ""}`;
    }
    if (months > 0) {
      durationString += `${
        durationString.length > 0 ? " " : ""
      }${months} month${months > 1 ? "s" : ""}`;
    }

    return durationString;
  };

  useEffect(() => {
    const getExperienceDetails = async () => {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/profile/experiences`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      if (responseData && responseData.data) {
        setExperienceList(
          responseData.data.map((item) => ({
            ...item,
            duration: calculateDuration(item.start_date, item.end_date),
            created_at: changeTimestampToDaysAgo(item.update_timestamp),
          }))
        );
      }
      setPageLoading(false);
    };
    getExperienceDetails();
  }, []);

  useEffect(() => {
    if (requestError) {
      toast(requestError, {
        autoClose: 3000,
        type: toast.TYPE.ERROR,
      });
      setPageLoading(false);
    }
  }, [requestError]);

  return (
    <>
      <div
        className="stu-skillbox-nav-header"
        onClick={() => history("/skillbox-v2")}
      >
        Home
      </div>
      <div className="profile-experience-page">
        <div className="profile-experience-header">
          <div className="profile-experience-header-title">
            My Profile - Work Experience
          </div>
          {experienceList && experienceList.length > 0 && (
            <Button
              text="Add New"
              width="100px"
              onClick={() => setShowWorkExperienceForm(true)}
            />
          )}
        </div>
        {pageLoading ? (
          <div className="profile-loader-container">
            <div className="profile-loader-sub-container">
              <Loading
                color="#14144e"
                customClass="profile-loader-container-for-loader"
              />
            </div>
          </div>
        ) : (
          <>
            <WorkExperienceForm
              title="Work Experience Details"
              onClose={() => setShowWorkExperienceForm(false)}
              show={showWorkExperienceForm}
              HandleSubmitFunction={addNewWorkExperience}
              setShowAddExperienceForm={setShowWorkExperienceForm}
            />
            <ToastContainer closeButton={false} />

            {experienceList && experienceList.length > 0 ? (
              <div className="profile-experience-section-1">
                {experienceList.map((experience) => (
                  <div className="profile-experience-card" key={experience.id}>
                    <div className="profile-experience-card-header">
                      <div className="profile-experience-card-header-section-1">
                        <div className="profile-experience-card-header-title">
                          {experience.job_position}
                          {" ["}
                          {getWorkTypeLabel(experience.work_type)}
                          {"]"}
                        </div>
                        <div className="profile-experience-card-header-subtitle">
                          {experience.company_name} - {experience.location}
                        </div>
                      </div>
                      <div className="profile-experience-card-header-section-2">
                        <div className="profile-experience-card-body-title-1">
                          {experience.created_at}
                        </div>
                      </div>
                    </div>
                    <div className="profile-experience-card-body">
                      <div className="profile-experience-card-body-container-1">
                        <div className="profile-experience-card-body-section-1">
                          <div className="profile-experience-card-body-title">
                            Start Date
                          </div>
                          <div className="profile-experience-card-body-data-value">
                            {experience.start_date
                              ? changeWorkDateFormat(experience.start_date)
                              : ""}
                          </div>
                        </div>
                        <div className="profile-experience-card-body-section-2">
                          <div className="profile-experience-card-body-title">
                            End Date
                          </div>
                          <div className="profile-experience-card-body-data-value">
                            {experience.end_date
                              ? experience.end_date === "Present"
                                ? "Present"
                                : changeWorkDateFormat(experience.end_date)
                              : ""}
                          </div>
                        </div>
                        <div className="profile-experience-card-body-section-3">
                          <div className="profile-experience-card-body-title">
                            Duration
                          </div>
                          <div className="profile-experience-card-body-data-value">
                            {experience.duration}
                          </div>
                        </div>
                      </div>
                      <div className="profile-experience-card-body-container-2">
                        <div className="profile-experience-card-body-title">
                          Description
                        </div>
                        <div className="profile-experience-card-body-data-value">
                          {experience.description}
                        </div>
                      </div>
                    </div>
                    <div className="profile-experience-card-footer">
                      <div
                        className="profile-experience-card-edit"
                        onClick={() => showEditExperiencePopup(experience)}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.41421 15.8895L16.5563 5.74738L15.1421 4.33316L5 14.4753V15.8895H6.41421ZM7.24264 17.8895H3V13.6468L14.435 2.21184C14.8256 1.82132 15.4587 1.82132 15.8492 2.21184L18.6777 5.04027C19.0682 5.43079 19.0682 6.06395 18.6777 6.45447L7.24264 17.8895ZM3 19.8895H21V21.8895H3V19.8895Z"
                            fill="#004C73"
                          />
                        </svg>
                        <div className="profile-experience-card-edit-title">
                          Edit Details
                        </div>
                      </div>
                      <div
                        className="profile-experience-card-delete"
                        onClick={() => showDeleteConfirmation(experience.id)}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7 4V2H17V4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7ZM6 6V20H18V6H6ZM9 9H11V17H9V9ZM13 9H15V17H13V9Z"
                            fill="#004C73"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="profile-experience-section-2">
                <div className="profile-experience-add-new">
                  <div className="profile-experience-add-new-title">
                    Add your experience details
                  </div>
                  <div className="profile-experience-add-new-subtitle">
                    Your school / college details
                  </div>
                  <Button
                    text="Add New"
                    width="100px"
                    onClick={() => setShowWorkExperienceForm(true)}
                  />
                </div>
              </div>
            )}
          </>
        )}
        <WorkExperienceForm
          title="Edit Experience Details"
          onClose={() => {
            setShowEditPopup(false);
          }}
          show={showEditPopup}
          HandleSubmitFunction={editWorkExperience}
          setShowAddExperienceForm={setShowEditPopup}
          editWorkExperienceData={editWorkExperienceData}
          isEdit={true}
        />
        <Delete
          show={showDeletePopup}
          text="Are you sure you want to delete this experience?"
          onClose={closeDeleteConfirmation}
          onDelete={() => deleteWorkExperience(deleteWorkId)}
        />
      </div>
    </>
  );
};

export default Experience;
