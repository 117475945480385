import React, { useState, useContext, useEffect } from "react";
import "./InterviewAttempted.css";
import { AuthContext } from "../../../../../../../context/auth-context";
import { useHttpClient } from "../../../../../../../Components/Hooks/http-hook";
import Loading from "../../../../../../../Components/Loading";
import Button from "../../../../../../../Components/Profile/Button/Button";
import moment from "moment";
import swal from "sweetalert2";
import DataTable from "react-data-table-component";

const PracticeAttempted = ({ handleShowScoreDashboard }) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [attemptedPractice, setAttemptedPractice] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(9);

  const handleUpgrade = () => {
    swal.fire({
      title: "Upgrade to Premium",
      text: "Premium features include unlimited tests, detailed analysis, and much more, it's about to come soon!",
      icon: "info",
    });
  };

  useEffect(() => {
    const fetchAttemptedTests = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/prep/get-all-previously-solved-practice`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            "Refresh-Token": auth.refresh_token,
            Authorization: auth.token,
          }
        );
        if (responseData.data) {
          setAttemptedPractice(responseData.data);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchAttemptedTests();
  }, [auth, sendRequest]);

  const handleDateFormatter = (date) => {
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  };

  const columns = [
    {
      name: "S.No.",
      selector: (_, index) => (currentPage - 1) * rowsPerPage + index + 1,
      width: "90px",
      center: true,
    },
    {
      name: "Practice Id",
      selector: (row) => row.practice_id,
      width: "300px",
    },
    {
      name: "Type",
      selector: (row) => row.type,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Score",
      selector: (row) => `${row.score_gained}/${row.total_score}`,
    },
    {
      name: "Time",
      selector: (row) => handleDateFormatter(row.practice_date),
      width: "250px",
    },
  ];

  return (
    <div>
      <div className="attempted-practice-header">
        <div className="attempted-practice-header-title">
          Practice Dashboard
        </div>
        <Button text="Upgrade to Premium" width="auto" onClick={handleUpgrade} />
      </div>
      {(isLoading || error) && (
        <div className="attempted-practice-body">
          <div className="attempted-practice-section">
            {isLoading && (
              <Loading
                color="#004c73"
                customClass="attempted-practice-interview-loading"
              />
            )}
            {!isLoading && error && (
              <div className="interview-questions-not-found-section">
                Something went wrong, Please try again later
              </div>
            )}
          </div>
        </div>
      )}
      {!isLoading && attemptedPractice && attemptedPractice.length === 0 && (
        <div className="attempted-practice-body">
          <div className="attempted-practice-section">
            <div className="interview-questions-not-found-section">
              No Interview Attempted
            </div>
          </div>
        </div>
      )}
      {!isLoading && attemptedPractice && attemptedPractice.length > 0 && (
        <div className="attempted-practice-body">
          <div className="attempted-practice-section">
            <DataTable
              columns={columns}
              data={attemptedPractice}
              onRowClicked={(row) => handleShowScoreDashboard(row.practice_id)}
              customStyles={{
                table: {
                  style: {
                    maxHeight: "600px", 
                    overflowY: "auto",  
                  },
                },        
                rows: {
                  style: {
                    minHeight: "48px",
                  },
                },
                headCells: {
                  style: {
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#004c73",
                  },
                },
                cells: {
                  style: {
                    fontSize: "14px",
                  },
                },
                pagination: {
                  style: {
                      marginTop: '20px',
                      justifyContent: 'flex-start',
                      border: 'none',
                      backgroundColor: 'none',
                  },
                  pageButtonsStyle: {
                    backgroundColor: '#fff',
                    borderRadius: '5px',
                    margin: '0px',
                    marginRight: '5px',
                    border: '1px solid #004C73',
                    cursor: 'pointer',
                    svg: {
                        fill: '#004C73',
                    },
                    '&:hover': {
                        backgroundColor: '#D2F0FF',
                        svg: {
                            fill: '#004C73',
                        },
                    },
                    '&:disabled': {
                        backgroundColor: '#fff',
                        border: '1px solid #ccc',
                        svg: {
                            fill: '#ccc',
                        },
                        '&:hover': {
                            backgroundColor: '#fff',
                        }
                    },
                    '&:active': {
                        backgroundColor: '#D2F0FF',
                        svg: {
                            fill: '#fff',
                        },
                    },
                  },
                }
              }}
              highlightOnHover
              pointerOnHover
              pagination
              paginationPerPage={rowsPerPage}
              onChangePage={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PracticeAttempted;