import React from "react";
import * as FaIcons from "react-icons/fa";
import LiveClass from "../Pages/LiveClass";
import ScheduledClass from "../Pages/ScheduledClass";
export const LiveClassesSideBarData = [
  {
    title: "Live Class",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 4C16.5523 4 17 4.44772 17 5V9.2L22.2133 5.55071C22.4395 5.39235 22.7513 5.44737 22.9096 5.6736C22.9684 5.75764 23 5.85774 23 5.96033V18.0397C23 18.3158 22.7761 18.5397 22.5 18.5397C22.3974 18.5397 22.2973 18.5081 22.2133 18.4493L17 14.8V19C17 19.5523 16.5523 20 16 20H2C1.44772 20 1 19.5523 1 19V5C1 4.44772 1.44772 4 2 4H16ZM15 6H3V18H15V6ZM7.4 8.82867C7.47607 8.82867 7.55057 8.85036 7.61475 8.8912L11.9697 11.6625C12.1561 11.7811 12.211 12.0284 12.0924 12.2148C12.061 12.2641 12.0191 12.306 11.9697 12.3375L7.61475 15.1088C7.42837 15.2274 7.18114 15.1725 7.06254 14.9861C7.02169 14.9219 7 14.8474 7 14.7713V9.22867C7 9.00776 7.17909 8.82867 7.4 8.82867ZM21 8.84131L17 11.641V12.359L21 15.1587V8.84131Z"
          fill="#004C73"
        />
      </svg>
    ),
    component: LiveClass,
  },
  {
    title: "Scheduled Class",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.8999 13.8001C6.8999 13.5615 6.99472 13.3325 7.16351 13.1638C7.33229 12.995 7.56121 12.9001 7.7999 12.9001H16.1999C16.4386 12.9001 16.6675 12.995 16.8363 13.1638C17.0051 13.3325 17.0999 13.5615 17.0999 13.8001C17.0999 14.0388 17.0051 14.2678 16.8363 14.4365C16.6675 14.6053 16.4386 14.7001 16.1999 14.7001H7.7999C7.56121 14.7001 7.33229 14.6053 7.16351 14.4365C6.99472 14.2678 6.8999 14.0388 6.8999 13.8001ZM6.8999 17.4001C6.8999 17.1615 6.99472 16.9325 7.16351 16.7638C7.33229 16.595 7.56121 16.5001 7.7999 16.5001H16.1999C16.4386 16.5001 16.6675 16.595 16.8363 16.7638C17.0051 16.9325 17.0999 17.1615 17.0999 17.4001C17.0999 17.6388 17.0051 17.8678 16.8363 18.0365C16.6675 18.2053 16.4386 18.3001 16.1999 18.3001H7.7999C7.56121 18.3001 7.33229 18.2053 7.16351 18.0365C6.99472 17.8678 6.8999 17.6388 6.8999 17.4001Z"
          fill="#434343"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3 3.0001C3 1.6777 4.0776 0.600098 5.4 0.600098H13.428C14.1024 0.600098 14.7384 0.885698 15.186 1.3573L15.1932 1.3657L20.3676 7.0081C20.7984 7.4677 21 8.0689 21 8.6401V21.0001C21 22.3225 19.9224 23.4001 18.6 23.4001H5.4C4.0776 23.4001 3 22.3225 3 21.0001V3.0001ZM13.4268 3.0001H5.4V21.0001H18.6V8.6305L13.4448 3.0085L13.4412 3.0073C13.4368 3.00412 13.432 3.00169 13.4268 3.0001Z"
          fill="#434343"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.428 0.600098C13.7463 0.600098 14.0515 0.726526 14.2766 0.95157C14.5016 1.17661 14.628 1.48184 14.628 1.8001V7.4401H19.8C19.9576 7.4401 20.1137 7.47114 20.2592 7.53144C20.4048 7.59175 20.5371 7.68014 20.6486 7.79157C20.76 7.903 20.8484 8.03529 20.9087 8.18088C20.969 8.32647 21 8.48251 21 8.6401C21 8.79768 20.969 8.95373 20.9087 9.09932C20.8484 9.24491 20.76 9.3772 20.6486 9.48863C20.5371 9.60006 20.4048 9.68845 20.2592 9.74875C20.1137 9.80906 19.9576 9.8401 19.8 9.8401H13.428C13.1098 9.8401 12.8045 9.71367 12.5795 9.48863C12.3545 9.26358 12.228 8.95836 12.228 8.6401V1.8001C12.228 1.48184 12.3545 1.17661 12.5795 0.95157C12.8045 0.726526 13.1098 0.600098 13.428 0.600098Z"
          fill="#434343"
        />
      </svg>
    ),
    component: ScheduledClass,
  },
];
