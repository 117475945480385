import React, { useState, useEffect, useContext } from "react";
import NavLegends from "../Components/NavLegends";
import "./InterviewAttempted.css";
import ScoreDashboardFirst from "./ScoreDashboardFirst";
import ScoreDashboardSecond from "./ScoreDashboardSecond";
import { AuthContext } from "../../../../../../../context/auth-context";
import { useHttpClient } from "../../../../../../../Components/Hooks/http-hook";
import Loading from "../../../../../../../Components/Loading";
import moment from "moment";

const ScoreDashboard = ({ id }) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [selectedData, setSelectedData] = useState(null);
  const [selectedQuestionId, setSelectedQuestionId] = useState(1);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [questionLength, setQuestionLength] = useState(null);
  const [selectDashboard, setSelectDashboard] = useState("first");

  useEffect(() => {
    const fetchInterviewData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/interview_prep/get_interview_preperation_exam_result_v2/${id}`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            "Refresh-Token": auth.refresh_token,
            Authorization: auth.token,
          }
        );
        if (responseData.data) {
          responseData.data.questions = responseData.data.questions.map(
            (question, index) => {
              return {
                ...question,
                id: index + 1,
              };
            }
          );
          setSelectedData(responseData.data);
          setQuestionLength(responseData.data.questions.length);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchInterviewData();
  }, [id]);

  const renderSelectedDashboard = () => {
    if (selectDashboard === "first") {
      return (
        <ScoreDashboardFirst
          selectedQuestion={selectedQuestion}
          selectedQuestionId={selectedQuestionId}
        />
      );
    } else if (selectDashboard === "second") {
      return <ScoreDashboardSecond selectedQuestion={selectedQuestion} />;
    }
  };

  const changeDateFormat = (date) => {
    return moment(date).format("DD/MM/YYYY");
  };

  return (
    <div>
      {(isLoading ||
        error ||
        (selectedData &&
          selectedData.exam &&
          selectedData.questions.length == 0)) && (
        <>
          <div className="interview-feedback-header">
            <div className="interview-feedback-header-title">
              Score Dashboard
            </div>
          </div>
          <div className="interview-feedback-body">
            <div className="interview-feedback-section">
              {isLoading && (
                <Loading
                  color="#004c73"
                  customClass="interview-feedback-interview-loading"
                />
              )}
              {error && (
                <div className="interview-questions-not-found-section">
                  Something went wrong, Please try again later
                </div>
              )}
              {selectedData &&
                selectedData.exam &&
                selectedData.questions.length == 0 && (
                  <div className="interview-questions-not-found-section">
                    No Questions Attempted
                  </div>
                )}
            </div>
          </div>
        </>
      )}
      {selectedData &&
        selectedData.exam &&
        selectedData.questions.length > 0 && (
          <>
            <div className="interview-feedback-header">
              <div className="interview-feedback-header-title">
                {selectedData && selectedData.exam && selectedData.exam.role && (
                  <>
                    Role -{" "}
                    {selectedData.exam.role}
                  </>
                )}{" "}
                {selectedData && selectedData.exam && selectedData.exam.skill && (
                  <>
                    Skill -{" "}
                    {selectedData.exam.skill}
                  </>
                )}{" "}
                {selectedData && selectedData.exam && selectedData.exam.companypackage && (
                  <>
                    Company Package -{" "}
                    {selectedData.exam.companypackage}
                  </>
                )}{" "}
                {selectedData && selectedData.exam && selectedData.exam.course && (
                  <>
                    Course -{" "}
                    {selectedData.exam.course}
                  </>
                )}{" "}
                {selectedData && selectedData.exam && selectedData.exam.exam_type=="profileBasedInterview" && (
                  <>
                    Resume -{" "}
                    {selectedData.exam.attempt_number}
                  </>
                )}{" "}
                {selectedData && selectedData.exam && selectedData.exam.exam_type=="communicationBasedInterview" && (
                  <>
                    Communication Based Interview
                  </>
                )}{" "}
                {selectedData && selectedData.exam && selectedData.exam.level && (
                  <>
                    | Level -{" "}
                    {selectedData.exam.level}
                  </>
                )}{" "}
                | Attempt -{" "}
                {selectedData &&
                  selectedData.exam &&
                  selectedData.exam.attempt_number}
              </div>
              <div className="interview-feedback-body-last-updated">
                Dated On:{" "}
                {selectedData && changeDateFormat(selectedData.exam.start_time)}
              </div>
            </div>
            <div className="interview-feedback-body">
              <div className="interview-feedback-section">
                <div className="interview-feedback-section-header">
                  <div className="interview-feedback-section-header-left">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.7701 12.4C14.9201 12.47 15.0901 12.5 15.2501 12.5C15.5801 12.5 15.8901 12.37 16.1301 12.14L18.3101 10H19.2501C20.7701 10 22.0001 8.77 22.0001 7.25V4.75C22.0001 3.23 20.7701 2 19.2501 2H14.7501C13.2301 2 12.0001 3.23 12.0001 4.75V7.25C12.0001 8.51 12.8501 9.57 14.0001 9.9V11.25C14.0001 11.75 14.3101 12.2 14.7701 12.4ZM13.5001 4.75C13.5001 4.06 14.0601 3.5 14.7501 3.5H19.2501C19.9401 3.5 20.5001 4.06 20.5001 4.75V7.25C20.5001 7.94 19.9401 8.5 19.2501 8.5H17.6901L15.5001 10.65V8.5H14.7501C14.0601 8.5 13.5001 7.94 13.5001 7.25V4.75ZM8.0001 13.5C6.0701 13.5 4.5001 11.93 4.5001 10C4.5001 8.07 6.0701 6.5 8.0001 6.5C9.9301 6.5 11.5001 8.07 11.5001 10C11.5001 11.93 9.9301 13.5 8.0001 13.5ZM8.0001 8C6.9001 8 6.0001 8.9 6.0001 10C6.0001 11.1 6.9001 12 8.0001 12C9.1001 12 10.0001 11.1 10.0001 10C10.0001 8.9 9.1001 8 8.0001 8ZM8.0001 22C5.9401 22 4.3601 21.44 3.3001 20.33C1.9641 18.926 1.9971 17.156 2.0001 16.973V16.96C2.0001 15.89 2.9001 15 4.0001 15H12.0001C13.1001 15 14.0001 15.9 14.0001 17L14.0011 17.006C14.0041 17.133 14.0461 18.916 12.7011 20.33C11.6401 21.44 10.0601 22 8.0001 22ZM4.0001 16.5C3.7201 16.5 3.5001 16.72 3.5001 17V17.005C3.5001 17.1 3.4831 18.353 4.4001 19.31C5.1601 20.1 6.3701 20.5 8.0001 20.5C9.6301 20.5 10.8501 20.09 11.6101 19.29C12.5231 18.338 12.5021 17.098 12.5001 17.032V17.03C12.5001 16.72 12.2701 16.49 12.0001 16.49H4.0001V16.5Z"
                        fill="#434343"
                      />
                    </svg>
                    <div className="interview-feedback-section-header-title">
                      Question Wise Feedback
                    </div>
                  </div>
                  <div className="interview-feedback-section-header-score">
                    {selectedData &&
                      selectedData.exam.obtained_score + " / " + "100"}
                  </div>
                </div>
                <div className="interview-feedback-section-body">
                  <div className="interview-feedback-section-body-part-1">
                    <NavLegends
                      selectedQuestionId={selectedQuestionId}
                      setSelectedQuestionId={setSelectedQuestionId}
                      questionLength={questionLength}
                      selectedData={selectedData}
                      setSelectedQuestion={setSelectedQuestion}
                      setSelectDashboard={setSelectDashboard}
                    />
                  </div>
                  {selectedQuestion && (
                    <div className="interview-feedback-section-body-part-2">
                      {renderSelectedDashboard()}
                    </div>
                  )}
                  <div className="interview-feedback-section-body-part-3">
                    <div>
                      {selectDashboard === "second" && (
                        <div
                          onClick={() => setSelectDashboard("first")}
                          className="interview-feedback-section-body-part-3-navigation"
                        >
                          Back
                        </div>
                      )}
                    </div>
                    <div>
                      {selectDashboard === "first" && (
                        <div
                          className="interview-feedback-section-body-part-3-navigation"
                          onClick={() => setSelectDashboard("second")}
                        >
                          Next
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default ScoreDashboard;
