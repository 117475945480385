import React from 'react';
import Button from "../../../../../../../../../Components/Profile/Button/Button";

const SelectLevel = ({
    selectedInterviewType,
    startInterviewHandler,
    setSelectedLevel,
    selectedLevel,
    selectedCourse,
    handleGoToHome,
    handleGoToAllInterview,
    handleGoToCourses,
    handleGoToSelectCourse
}) => {

    const level = [
        { value: "easy", label: "Beginner" },
        { value: "medium", label: "Intermediate" },
        { value: "hard", label: "Advanced" },
    ];
    
    return (
        <div className="interview-preperation-personal-interview-page">
            <div className="interview-preperation-personal-interview-page-nav-menu">
                <div
                    onClick={handleGoToHome}
                    className="interview-preperation-personal-interview-page-nav-menu-home"
                >
                    Home
                </div>
                &nbsp; &gt; &nbsp;
                <div
                    onClick={handleGoToAllInterview}
                    className="interview-preperation-personal-interview-page-nav-menu-other"
                >
                    All Interview
                </div>
                &nbsp; &gt; &nbsp;
                <div
                    onClick={handleGoToCourses}
                    className="interview-preperation-personal-interview-page-nav-menu-other"
                >
                    Course Based
                </div>
                &nbsp; &gt; &nbsp;
                <div
                    onClick={handleGoToSelectCourse}
                    className="interview-preperation-personal-interview-page-nav-menu-other"
                >
                    Select Course
                </div>
            </div>
            <div className="interview-preperation-personal-interview-container">
                <div className="interview-preperation-personal-interview-header">
                    <div className="interview-preperation-personal-interview-header-title">
                        {selectedInterviewType.title}
                    </div>
                </div>
                <div className="interview-preperation-personal-interview-body">
                    <div className="interview-preperation-personal-interview-body-4">
                        <div className="interview-preperation-personal-interview-body-4-1">
                            <div className="interview-preperation-personal-interview-title">
                                Select Level
                            </div>
                        </div>
                        <div className="interview-preperation-personal-interview-body-4-2">
                            <div className="interview-preperation-personal-interview-role-container-wrapper">
                                <div className="interview-preperation-personal-interview-role-container">
                                    {level.map((lvl, index) => (
                                        <div
                                            key={index}
                                            className={`interview-preperation-personal-interview-box ${selectedLevel === lvl.value ? 'selected' : ''}`}
                                            onClick={() => setSelectedLevel(lvl.value)}
                                        >
                                            {lvl.label}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="interview-preperation-personal-interview-body-4-3">
                            <Button
                                text="START INTERVIEW"
                                width="400px"
                                disabled={selectedLevel === ""}
                                onClick={() => startInterviewHandler({ course: selectedCourse, level: selectedLevel })}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectLevel;

