import React, { useState, useEffect, useRef, useContext } from "react";
import "./Resume.css";
import Button from "../../../../Components/Profile/Button/Button";
import { useHttpClient } from "../../../../Components/Hooks/http-hook";
import { AuthContext } from "../../../../context/auth-context";
import Loading from "../../../../Components/Loading";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { useNavigate } from "react-router";

Blob.prototype.convertToBase64 = function() {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = (e) => resolve(e.target.result);
    reader.readAsDataURL(this);
  });
};

const RenderFile = ({ resumeUrl }) => {
  return (
    <>
      {resumeUrl ? (
        <iframe
          src={resumeUrl}
          title="Resume.pdf"
          width="300px"
          height="350px"
        />
      ) : (
        <Loading color="#14144e" />
      )}
    </>
  );
};

const Resume = () => {
  const [fileData, setFileData] = useState(null);
  const [resumeDetails, setResumeDetails] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);
  const [resumeUrl, setResumeUrl] = useState(null);
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const history = useNavigate();
  const fileInput = useRef(null);

  const handleFileChange = (e) => {
    if (
      e.target.files[0] &&
      e.target.files[0].size > 10000000 &&
      e.target.files[0].size < 10000
    ) {
      setFileData(null);
      toast("File size should be less than 10MB", {
        autoClose: 3000,
        type: "error",
      });
    } else {
      setResumeUrl(URL.createObjectURL(e.target.files[0]));
      setResumeDetails({
        ...resumeDetails,
        updated_at: new Date().toISOString(),
      });
      uploadResume(e.target.files[0]);
    }
  };

  const uploadResume = async (file) => {
    const base64 = await file.convertToBase64();
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/profile/resume`,
      "POST",
      JSON.stringify({ resume: base64 }),
      {
        "Content-Type": "application/json",
        "Refresh-Token": auth.refresh_token,
        Authorization: auth.token,
      }
    );
  };

  useEffect(() => {
    const getResumeDetails = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/profile/resume`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            "Refresh-Token": auth.refresh_token,
            Authorization: auth.token,
          }
        );
        if (responseData.data !== "NA") {
          setResumeDetails(responseData.data);
          setResumeDetails({
            ...resumeDetails,
            updated_at: moment(responseData.data.update_timestamp).format(
              "DD/MM/YYYY"
            ),
          });
          const isValidUrl = (string) => {
            try {
              new URL(string);
            } catch (_) {
              return false;
            }
            return true;
          };
          if (responseData.data.resume_url !== null) {
            if (isValidUrl(responseData.data.resume_url)) {
              setResumeUrl(responseData.data.resume_url);
            } else {
              setResumeUrl(
                `${process.env.REACT_APP_BACKEND_URL}/${responseData.data.resume_url}`
              );
            }
          }
        }
        setPageLoading(false);
      } catch (err) {
        setPageLoading(false);
      }
    };
    getResumeDetails();
  }, []);

  return (
    <>
    <div
      className="stu-skillbox-nav-header"
      onClick={() => history("/skillbox-v2")}
    >
      Home
    </div>
    <div className="profile-user-resume-page">
      {/* <div className="profile-content-header-nav-menu">Home</div> */}
      <ToastContainer closeButton={false} />
      <div className="profile-user-resume-header">
        <div className="profile-user-resume-header-title">
          My Resume - Upload Resume
        </div>
        {resumeDetails && resumeUrl && (
          <Button
            text="Update"
            width="100px"
            onClick={() => fileInput.current.click()}
          />
        )}
        <input
          type="file"
          onChange={handleFileChange}
          style={{ display: "none" }}
          ref={fileInput}
          multiple={false}
          accept=".pdf"
        />
      </div>
      {pageLoading ? (
        <div className="profile-loader-container">
          <div className="profile-loader-sub-container">
            <Loading
              color="#14144e"
              customClass="profile-loader-container-for-loader"
            />
          </div>
        </div>
      ) : (
        <div className="profile-user-resume">
          {resumeDetails && resumeUrl ? (
            <div className="profile-user-resume__view">
              <div className="profile-user-resume__view__details">
                <div className="profile-user-resume-view-details-section-1">
                  <span>File Name:</span>
                  Resume.pdf
                </div>
                <div className="profile-user-resume-view-details-section-2">
                  <span>Uploaded At:</span>
                  {resumeDetails.updated_at}
                </div>
              </div>
              <div className="profile-user-resume__view__details__download">
                <RenderFile resumeUrl={resumeUrl} />
              </div>
            </div>
          ) : (
            <div className="profile-user-resume__view__no-resume">
              <div className="profile-user-upload-new-container-1">
              <div className="profile-project-card-header-title">
              Add Resume
              </div>
              </div>
              <div className="profile-user-upload-new-container-2">
              <div className="profile-experience-card-header-subtitle">
                Upload your resume
              </div>
              </div>
              <Button
                text="Add New"
                width="100px"
                onClick={() => fileInput.current.click()}
              />
            </div>
          )}
        </div>
      )}
      </div>
    </>
  );
};

export default Resume;
