import React from "react";
const InterviewFeedbackWrapper = ({ children }) => {
  return (
    <div className="interview-feedback-body">
      <div className="interview-feedback-section">
        {children}
      </div>
    </div>
  );
};

export default InterviewFeedbackWrapper;
