import React from "react";
import Button from "../../../../../../../../../Components/Profile/Button/Button";

const SelectLevel = ({
    setSelectedLevel,
    selectedLevel,
    selectedRole,
    selectedInterviewType,
    startInterviewHandler,
    handleGoToHome,
    handleGoToAllInterview,
    handleGoToRoles,
    handleGoToSelectRole
}) => {
    const level = [
        { value: "entry", label: "Entry" },
        { value: "midlevel", label: "Mid-Level" },
        { value: "experienced", label: "Experienced" },
    ];

    const startRoleBasedInterview = () => {
        startInterviewHandler({
            role: selectedRole,
            joblevel: selectedLevel,
        });
    };

    return (
        <div className="interview-preperation-personal-interview-page">
            <div className="interview-preperation-personal-interview-page-nav-menu">
                <div
                    onClick={handleGoToHome}
                    className="interview-preperation-personal-interview-page-nav-menu-home"
                >
                    Home
                </div>
                &nbsp; &gt; &nbsp;
                <div
                    onClick={handleGoToAllInterview}
                    className="interview-preperation-personal-interview-page-nav-menu-other"
                >
                    All Interview
                </div>
                &nbsp; &gt; &nbsp;
                <div
                    onClick={handleGoToRoles}
                    className="interview-preperation-personal-interview-page-nav-menu-other"
                >
                    Role Based
                </div>
                &nbsp; &gt; &nbsp;
                <div
                    onClick={handleGoToSelectRole}
                    className="interview-preperation-personal-interview-page-nav-menu-other"
                >
                    Select Roles
                </div>
            </div>
            <div className="interview-preperation-personal-interview-container">
                <div className="interview-preperation-personal-interview-header">
                    <div className="interview-preperation-personal-interview-header-title">
                        {selectedInterviewType.title}
                    </div>
                </div>
                <div className="interview-preperation-personal-interview-body">
                    <div className="interview-preperation-personal-interview-body-4">
                        <div className="interview-preperation-personal-interview-body-4-1">
                            <div className="interview-preperation-personal-interview-title">
                                Select Level
                            </div>
                        </div>
                        <div className="interview-preperation-personal-interview-body-4-2">
                            <div className="interview-preperation-personal-interview-level-container">
                                {level.map((lvl, index) => (
                                    <div
                                        key={index}
                                        className={`interview-preperation-personal-interview-box ${selectedLevel === lvl.value ? 'selected' : ''}`}
                                        onClick={() => setSelectedLevel(lvl.value)}
                                    >
                                        {lvl.label}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="interview-preperation-personal-interview-body-4-3">
                            <Button
                                text="START INTERVIEW"
                                width="400px"
                                disabled={selectedLevel === ""}
                                onClick={startRoleBasedInterview}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectLevel;
