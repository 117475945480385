import React from 'react';
import './NotificationPopup.css';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../../Components/Profile/Button/Button';
import { FaBell, FaInfoCircle, FaExclamationTriangle, FaClock } from 'react-icons/fa';

const NotificationPopup = ({ notifications, onMarkAllRead, onSeeAll, onClose }) => {
  const navigate = useNavigate();
  const unreadCount = notifications.filter(notif => !notif.read).length;

  const getNotificationIcon = (type) => {
    switch (type) {
      case 'urgent':
        return <FaExclamationTriangle className="notification-icon urgent" />;
      case 'info':
        return <FaInfoCircle className="notification-icon info" />;
      case 'reminder':
        return <FaClock className="notification-icon reminder" />;
      default:
        return <FaBell className="notification-icon default" />;
    }
  };

  const handleNotificationClick = (notification) => {
    if (notification.actionUrl) {
      navigate(notification.actionUrl);
      onClose();
    }
  };

  // Only show top 3 notifications
  const topNotifications = notifications.slice(0, 3);

  return (
    <div className="notification-popup">
      <div className="notification-header">
        <div className="notification-header__title">Notifications</div>
        {unreadCount > 0 && <span className="new-tag">{unreadCount} new notifications</span>}
      </div>
      
      <div className="notification-list">
        {notifications.length === 0 ? (
          <div className="no-notifications">
            <FaBell className="no-notifications-icon" />
            <p>No notifications yet</p>
          </div>
        ) : (
          topNotifications.map((notification) => (
            <div 
              key={notification.id} 
              className={`notification-item ${!notification.read ? 'unread' : ''} ${notification.type}`}
              onClick={() => handleNotificationClick(notification)}
            >
              {getNotificationIcon(notification.type)}
              <div className="notification-content">
                <p>{notification.message}</p>
                <span className="notification-time">{notification.time}</span>
              </div>
              {!notification.read && <div className="unread-dot" />}
            </div>
          ))
        )}
      </div>

      {notifications.length > 0 && (
        <div className="notification-actions">
          <div className="notification-actions__mark-as-read-btn" onClick={onMarkAllRead}>
              Mark all as read
          </div>
          <div className="notification-actions__see-all-btn" onClick={onSeeAll}>
            See all
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationPopup; 