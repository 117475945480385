import React, { useState } from 'react';
import './Permissions.css';

const Permissions = ({ onClose, onSuccess, onError }) => {
    const [permissionStatus, setPermissionStatus] = useState('');

    const requestPermissions = async () => {
        try {
            const mediaStream = await navigator.mediaDevices.getUserMedia({
                audio: true,
                video: true
            });
            
            // Stop the stream immediately after getting permissions
            mediaStream.getTracks().forEach(track => track.stop());
            
            setPermissionStatus('success');
            onSuccess();
        } catch (error) {
            setPermissionStatus('error');
            onError();
        }
    };

    return (
        <div className="sk-interview-permissions-overlay">
            <div className="sk-interview-permissions-modal">
                <div className="sk-interview-permissions-modal-title">skillbox.surgeclasses.com wants to</div>
                <div className="sk-interview-permissions-modal-description">We need access to your camera and microphone for the interview session. Please allow access to continue.</div>
                
                {permissionStatus === 'error' && (
                    <div className="sk-interview-permissions-error">
                        Please allow camera and microphone access to continue.
                        Check your browser settings if permissions were denied.
                    </div>
                )}

                <div className="sk-interview-permissions-buttons">
                    <button 
                        className="sk-interview-permissions-button primary"
                        onClick={requestPermissions}
                    >
                        Allow Access
                    </button>
                    <button 
                        className="sk-interview-permissions-button secondary"
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Permissions;
