import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useHttpClient } from '../../../../../../../../../Components/Hooks/http-hook';
import { AuthContext } from '../../../../../../../../../context/auth-context';
import Loading from '../../../../../../../../../Components/Loading';
import "./Certificates.css";
import CourseCertCard from './Components/CourseCertCard';
import ModuleCertCard from './Components/ModuleCertCard';
import { IoChevronDownOutline } from "react-icons/io5";
import CertificateViewPopup from './Components/CertificateViewPopup';

const Certificates = () => {
    const navigate = useNavigate();
    const { cid, ct } = useParams();
    const [selectedCertType, setSelectedCertType] = useState('course');
    const [pathways, setPathways] = useState([]);
    const [courseProgress, setCourseProgress] = useState(null);
    const [showCertificate, setShowCertificate] = useState(false);
    const [certificateData, setCertificateData] = useState(null);
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const [modules, setModules] = useState({});  // Store modules for each pathway
    const [isLoadingModules, setIsLoadingModules] = useState(false);

    const { isLoading, error, sendRequest } = useHttpClient();
    const auth = useContext(AuthContext);

    const goToHome = () => {
        navigate("/skillbox-v2");
    };

    const getCoursePathways = async () => {
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/db/sections/${cid}`,
                "GET",
                null,
                {
                    "Content-Type": "application/json",
                    "Refresh-Token": auth.refresh_token,
                    Authorization: auth.token,
                }
            );
            if (responseData && responseData.ok && responseData.data) {
                setPathways(responseData.data);
            }
        } catch (err) {
            console.log("Error fetching pathways:", err);
        }
    };

    const fetchModulesForPathway = async (pathwayId) => {
        setIsLoadingModules(true);
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/db/modules/${pathwayId}`,
                "GET",
                null,
                {
                    "Content-Type": "application/json",
                    "Refresh-Token": auth.refresh_token,
                    Authorization: auth.token,
                }
            );
            if (responseData.data && responseData.data.length > 0) {
                setModules(prev => ({
                    ...prev,
                    [pathwayId]: responseData.data
                }));
            }
        } catch (err) {
            console.log("Error fetching modules:", err);
        } finally {
            setIsLoadingModules(false);
        }
    };

    useEffect(() => {
        getCoursePathways();
        setCourseProgress({
            completed: 5,
            total: 10,
            isUnlocked: false,
            certificate: "https://surgeclasses.com/static/media/selfpaceprogramcertsample.5d97c1867c2d1d13da9b.png"
        });
    }, []);

    // Fetch modules when pathway is selected
    useEffect(() => {
        if (selectedCertType.startsWith('pathway_')) {
            const pathwayId = selectedCertType.split('pathway_')[1];
            if (!modules[pathwayId]) {
                fetchModulesForPathway(pathwayId);
            }
        }
    }, [selectedCertType]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            const selectElement = document.querySelector('.stu-skillbox-cert-custom-select');
            if (selectElement && !selectElement.contains(event.target)) {
                setIsSelectOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleViewCertificate = (certificate) => {
        setCertificateData(certificate);
        setShowCertificate(true);
    };

    const handleDownload = () => {
        // Implement certificate download logic
        console.log('Downloading certificate...');
    };

    const handleShareLinkedIn = () => {
        // Implement LinkedIn sharing logic
        console.log('Sharing to LinkedIn...');
    };

    // Prepare combined options for the single select
    const getCertificateOptions = () => {
        const options = [
            { value: 'course', label: 'Course Certificate' },
            ...pathways.map(pathway => ({
                value: `pathway_${pathway.section_id}`,
                label: pathway.section_name
            }))
        ];
        return options;
    };

    return (
        <div className="stu-skillbox-certificates-page">
            <div className="stu-skillbox-certificates-fixed-header">
                <div className="stu-skillbox-certificates-nav-header">
                    <span onClick={goToHome}>Home</span>
                </div>

                <div className="stu-skillbox-certificates-header">
                    <div className="stu-skillbox-certificates-title">
                        <h1>Module and Course Certificates</h1>
                    </div>
                    <div className="stu-skillbox-certificates-select">
                        <div
                            className={`stu-skillbox-cert-custom-select ${isSelectOpen ? 'open' : ''}`}
                            onClick={() => !isLoading && setIsSelectOpen(!isSelectOpen)}
                        >
                            <div className="stu-skillbox-cert-selected-option">
                                {isLoading ? (
                                    <span className="stu-skillbox-cert-loading">Loading...</span>
                                ) : (
                                    <>
                                        {getCertificateOptions().find(opt => opt.value === selectedCertType) && getCertificateOptions().find(opt => opt.value === selectedCertType).label}
                                        <IoChevronDownOutline className="stu-skillbox-cert-select-arrow" />
                                    </>
                                )}
                            </div>
                            {!isLoading && isSelectOpen && (
                                <div className="stu-skillbox-cert-options-container">
                                    {getCertificateOptions().map(option => (
                                        <div
                                            key={option.value}
                                            className={`stu-skillbox-cert-option ${selectedCertType === option.value ? 'selected' : ''}`}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setSelectedCertType(option.value);
                                                setIsSelectOpen(false);
                                            }}
                                        >
                                            {option.label}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="stu-skillbox-certificates-content">
                {isLoading ? (
                    <div className="stu-skillbox-certificates-loading">
                        <Loading color="#004c73" customClass="st-cls-interview-loading" />
                    </div>
                ) : (
                    <>
                        {selectedCertType === 'course' && courseProgress && (
                            <CourseCertCard
                                courseName={ct}
                                progress={courseProgress}
                                onViewCertificate={() => handleViewCertificate(courseProgress.certificate)}
                            />
                        )}

                        {selectedCertType.startsWith('pathway_') && (
                            <div className="stu-module-certificates-grid">
                                {isLoadingModules ? (
                                    <div className="stu-skillbox-certificates-loading">
                                        <Loading color="#004c73" customClass="st-cls-interview-loading" />
                                    </div>
                                ) : (
                                    <>
                                        {modules[selectedCertType.split('pathway_')[1]] && modules[selectedCertType.split('pathway_')[1]].map((module, index) => (
                                            <ModuleCertCard
                                                key={module.module_id || index}
                                                module={module}
                                                onViewCertificate={handleViewCertificate}
                                            />
                                        ))}
                                    </>
                                )}
                            </div>
                        )}
                    </>
                )}
            </div>

            {showCertificate && (
                <CertificateViewPopup 
                    certificateUrl={certificateData}
                    onClose={() => setShowCertificate(false)}
                    onDownload={handleDownload}
                    onShareLinkedIn={handleShareLinkedIn}
                />
            )}
        </div>
    );
};

export default Certificates;