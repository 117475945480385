import React, { useState } from 'react';
import './InterviewInstructions.css';

const InterviewInstructions = ({ onClose, onStart, isLoading }) => {
    const [isChecked, setIsChecked] = useState(false);

    const instructions = [
        <>This online test is being conducted for your <strong>educational benefit</strong>, to gauge your learning progress, and to measure your understanding. Please be <strong>attentive</strong> and answer all questions to the best of your ability.</>,
        <>The test comprises of <strong>variable number of questions</strong>.</>,
        <>Ensure a <strong>stable internet connection</strong> to avoid any disruptions during the test. <strong>Do not refresh the page</strong>, as this may result in data loss or disqualification from the test.</>,
        <>This test is open to all eligible participants, and there are <strong>no restrictions</strong> based on age, gender, or any other criteria.</>,
        <>We expect all participants to maintain the <strong>highest level of integrity and honesty</strong> during the test. Any attempt to use external aids or engage in cheating will result in <strong>immediate disqualification</strong>.</>,
        <>We wish you the <strong>best of luck</strong> as you take this interview test. We hope that it provides valuable insights into your capabilities.</>,
    ];

    return (
        <div className="sk-interview-instructions">
            <h2>Instructions for Interview</h2>
            
            <div className="sk-interview-instructions-list">
                {instructions.map((instruction, index) => (
                    <div key={index} className="sk-interview-instruction-item">
                        <span className="sk-interview-instruction-number">{index + 1}.</span>
                        <span className="sk-interview-instruction-text">{instruction}</span>
                    </div>
                ))}
            </div>

            <div className="sk-interview-instructions-checkbox">
                <label>
                    <input 
                        type="checkbox" 
                        checked={isChecked}
                        onChange={(e) => setIsChecked(e.target.checked)}
                    />
                    <span>I have read and understood all the instructions</span>
                </label>
            </div>

            <div className="sk-interview-instructions-buttons">
                <button 
                    className="sk-interview-instructions-button primary"
                    disabled={!isChecked || isLoading}
                    onClick={onStart}
                >
                    {isLoading ? (
                        <div className="sk-interview-instructions-button-content">
                            <span className="sk-interview-instructions-loader"></span>
                            CREATING EXAM...
                        </div>
                    ) : (
                        'NEXT'
                    )}
                </button>
            </div>
        </div>
    );
};

export default InterviewInstructions;
