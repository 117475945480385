import React, { useState, useEffect, useContext } from "react";
import "./Referral.css";
import Navbar from "../../../Components/SkillboxNavbar/Navbar";
import { ProfileDropdownValues } from "../StudentProfile/Data/ProfileDropdownValues";
import { ReferralSideBarData } from "./Data/SideBarData";
import Sidebar from "../../../Components/Profile/Sidebar/Sidebar";
import { useNavigate } from "react-router";

const Referral = ({ isNoHeaderFooter, setIsNoHeaderFooter }) => {
  const [sidebarComponent, setSidebarComponent] = useState(
    ReferralSideBarData[0].component
  );
  const [selectedSidebarIndex, setSelectedSidebarIndex] = useState(0);

  const history = useNavigate();

  const renderSidebar = (data) => {
    setSidebarComponent(data.component);
  };

  useEffect(() => {
    renderSidebar(ReferralSideBarData[0]);
  }, []);


  return (
    <>
      <div className="referral-page">
        <Sidebar
          SidebarData={ReferralSideBarData}
          renderSidebar={renderSidebar}
          setIsNoHeaderFooter={setIsNoHeaderFooter}
          selectedSidebarIndex={selectedSidebarIndex}
          setSelectedSidebarIndex={setSelectedSidebarIndex}
        />
        <div className="referral-page-content">
          <div className="referral-page-content-header">
            <Navbar
                setIsNoHeaderFooter={setIsNoHeaderFooter}
                setSelectedSidebarIndex={setSelectedSidebarIndex}
                currentOptionIndex={3}
                dropdownValues={ProfileDropdownValues}
            />
          </div>
          <div className="referral-page-content-body">{sidebarComponent}</div>
        </div>
      </div>
    </>
  );
};

export default Referral;
