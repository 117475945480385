import React from 'react';
import RoleBasedInterview from "../PersonalInterview/InterviewTypes/RoleBased/RoleBasedInterview";
import SkillBasedInterview from "../PersonalInterview/InterviewTypes/SkillBased/SkillBasedInterview";
import CommunicationBasedInterview from "../PersonalInterview/InterviewTypes/CommunicationBased/CommunicationBasedInterview";
import PlacementPrepInterview from "../PersonalInterview/InterviewTypes/PlacementBased/PlacementPrepInterview";
import ProfileBasedInterview from "../PersonalInterview/InterviewTypes/ProfileBased/ProfileBasedInterview";
import CourseBasedInterview from "../PersonalInterview/InterviewTypes/CourseBased/CourseBasedInterview";

const InterviewTabs = ({ selectedInterviewType, ...props }) => {
  const renderInterviewComponent = () => {
    switch (selectedInterviewType.type_slug) {
      case 'roleBasedInterview':
        return <RoleBasedInterview {...props} selectedInterviewType={selectedInterviewType} />;
      case 'skillBasedInterview':
        return <SkillBasedInterview {...props} selectedInterviewType={selectedInterviewType} />;
      case 'communicationBasedInterview':
        return <CommunicationBasedInterview {...props} selectedInterviewType={selectedInterviewType} />;
      case 'placementBasedInterview':
        return <PlacementPrepInterview {...props} selectedInterviewType={selectedInterviewType} />;
      case 'profileBasedInterview':
        return <ProfileBasedInterview {...props} selectedInterviewType={selectedInterviewType} />;
      case 'courseBasedInterview':
        return <CourseBasedInterview {...props} selectedInterviewType={selectedInterviewType} />;
      default:
        return null;
    }
  };

  return (
    <div className="interview-content">
      {renderInterviewComponent()}
    </div>
  );
};

export default InterviewTabs;
