import React, { useState } from "react";
import "./ScheduledClass.css";
import DataTable from "react-data-table-component";
import Button from "../../../../../../Components/Profile/Button/Button";
import Loading from "../../../../../../Components/Loading";
import { IoClose } from "react-icons/io5";

const ScheduledClass = () => {
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(9);
  const [isLoading] = useState(false);

  // Dummy data - replace with actual API data
  const courses = [
    // { id: 1, name: "Full Stack Development" },
    // { id: 2, name: "Data Science" },
    // Add more courses
  ];

  const scheduledClasses = [
    // {
    //   id: 1,
    //   courseId: 1,
    //   moduleName: "Frontend Development",
    //   className: "React Fundamentals",
    //   date: "2024-03-25",
    //   time: "10:00 AM",
    //   instructorName: "John Doe",
    //   pathwayName: "MERN Stack",
    //   duration: "2 hours",
    //   platform: "Google Meet",
    // },
    // Add more classes
  ];

  const filteredClasses =
    selectedCourse === "all"
      ? scheduledClasses
      : scheduledClasses.filter(
          (cls) => cls.courseId === parseInt(selectedCourse)
        );

  const columns = [
    {
      name: "S.No.",
      selector: (_, index) => (currentPage - 1) * rowsPerPage + index + 1,
      width: "90px",
      center: true,
    },
    {
      name: "Module",
      selector: (row) => row.moduleName,
      width: "200px",
    },
    {
      name: "Class",
      selector: (row) => row.className,
      width: "200px",
    },
    {
      name: "Date",
      selector: (row) => row.date,
      width: "150px",
    },
    {
      name: "Time",
      selector: (row) => row.time,
      width: "150px",
    },
  ];

  const customStyles = {
    table: {
      style: {
        maxHeight: "600px",
        overflowY: "auto",
      },
    },
    rows: {
      style: {
        minHeight: "48px",
      },
    },
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        color: "#004c73",
      },
    },
    cells: {
      style: {
        fontSize: "14px",
      },
    },
    pagination: {
      style: {
        marginTop: "20px",
        justifyContent: "flex-start",
        border: "none",
        backgroundColor: "none",
      },
      pageButtonsStyle: {
        backgroundColor: "#fff",
        borderRadius: "5px",
        margin: "0px",
        marginRight: "5px",
        border: "1px solid #004C73",
        cursor: "pointer",
        svg: {
          fill: "#004C73",
        },
        "&:hover": {
          backgroundColor: "#D2F0FF",
          svg: {
            fill: "#004C73",
          },
        },
        "&:disabled": {
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          svg: {
            fill: "#ccc",
          },
          "&:hover": {
            backgroundColor: "#fff",
          },
        },
        "&:active": {
          backgroundColor: "#D2F0FF",
          svg: {
            fill: "#fff",
          },
        },
      },
    },
  };

  const handleClickOutside = (e) => {
    if (e.target.classList.contains('sc-popup-overlay')) {
      setSelectedClass(null);
    }
  };

  return (
    <div className="sc-container">
      <div className="sc-header">
        <div className="sc-header-title">Scheduled Classes</div>
        <div className="sc-filter-section">
          <select
            className="sc-filter-select"
            value={selectedCourse}
            onChange={(e) => setSelectedCourse(e.target.value)}
          >
            <option value="all">All Courses</option>
            {courses.map((course) => (
              <option key={course.id} value={course.id}>
                {course.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="sc-body">
        <div className="sc-section">
          {isLoading && <Loading color="#004c73" customClass="sc-loading" />}

          {!isLoading && filteredClasses.length === 0 && (
            <div className="sc-not-found">No scheduled classes found</div>
          )}

          {!isLoading && filteredClasses.length > 0 && (
            <DataTable
              columns={columns}
              data={filteredClasses}
              onRowClicked={(row) => setSelectedClass(row)}
              customStyles={customStyles}
              highlightOnHover
              pointerOnHover
              pagination
              paginationPerPage={rowsPerPage}
              onChangePage={(page) => setCurrentPage(page)}
            />
          )}
        </div>
      </div>

      {selectedClass && (
        <div className="sc-popup-overlay" onClick={handleClickOutside}>
          <div className="sc-popup">
            <div className="sc-popup-header">
              <span>Class Details</span>
              <IoClose 
                className="sc-close-icon"
                onClick={() => setSelectedClass(null)}
              />
            </div>
            <div className="sc-popup-content">
              <div className="sc-detail-grid">
                <div className="sc-detail-item">
                  <div className="sc-detail-label">Pathway</div>
                  <div className="sc-detail-value">
                    {selectedClass.pathwayName}
                  </div>
                </div>
                <div className="sc-detail-item">
                  <div className="sc-detail-label">Module</div>
                  <div className="sc-detail-value">
                    {selectedClass.moduleName}
                  </div>
                </div>
                <div className="sc-detail-item">
                  <div className="sc-detail-label">Class</div>
                  <div className="sc-detail-value">
                    {selectedClass.className}
                  </div>
                </div>
                <div className="sc-detail-item">
                  <div className="sc-detail-label">Instructor</div>
                  <div className="sc-detail-value">
                    {selectedClass.instructorName}
                  </div>
                </div>
                <div className="sc-detail-item">
                  <div className="sc-detail-label">Date</div>
                  <div className="sc-detail-value">{selectedClass.date}</div>
                </div>
                <div className="sc-detail-item">
                  <div className="sc-detail-label">Time</div>
                  <div className="sc-detail-value">{selectedClass.time}</div>
                </div>
                <div className="sc-detail-item">
                  <div className="sc-detail-label">Duration</div>
                  <div className="sc-detail-value">
                    {selectedClass.duration}
                  </div>
                </div>
                <div className="sc-detail-item">
                  <div className="sc-detail-label">Platform</div>
                  <div className="sc-detail-value">
                    {selectedClass.platform}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ScheduledClass;
