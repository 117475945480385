import React from 'react';
import './ModuleCertCard.css';
import Button from '../../../../../../../../../../Components/Profile/Button/Button';
import { FaCheckCircle, FaClock, FaCertificate } from 'react-icons/fa';

const ModuleCertCard = ({ module, onViewCertificate }) => {
    const { module_name } = module;
    const isCompleted = false;
    const certificate = "https://surgeclasses.com/static/media/selfpaceprogramcertsample.5d97c1867c2d1d13da9b.png"; 

    return (
        <div className={`stu-module-cert-card ${isCompleted ? 'completed' : 'pending'}`}>
            <div className="stu-module-cert-content">
                <div className="stu-module-cert-header">
                    <div className="stu-module-cert-title" title={module_name}>
                        {module_name}
                    </div>
                    <div className={`stu-module-cert-status ${isCompleted ? 'completed' : 'pending'}`}>
                        {isCompleted ? (
                            <><FaCheckCircle className="status-icon" /> Completed</>
                        ) : (
                            <><FaClock className="status-icon" /> In Progress</>
                        )}
                    </div>
                </div>
                 {/* add text once mentor approves certificate  will be available*/}
                 <div className="stu-module-cert-instructions-text">
                    Once mentor approves certificate will be available
                 </div>
                <Button
                    text="View Certificate"
                    onClick={() => onViewCertificate(certificate)}
                    disabled={!isCompleted}
                    width="auto"
                />
            </div>
        </div>
    );
};

export default ModuleCertCard;
