import React, { useState, useMemo, useEffect, useContext } from "react";
import Button from "../../../../../../../../../Components/Profile/Button/Button";
import Loading from "../../../../../../../../../Components/Loading";
import { AuthContext } from "../../../../../../../../../context/auth-context";
import { useHttpClient } from "../../../../../../../../../Components/Hooks/http-hook";
const swal = require("sweetalert2");

const Roles = ({
    selectedInterviewType,
    setSelectedRole,
    selectedRole,
    handleGoToHome,
    handleGoToAllInterview,
    handleGoToRoleBased,
    onNext,
}) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [roles, setRoles] = useState([]);
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();

    const getRoles = async () => {
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/api/interview_prep/get_all_interview_preparation_roles_v2`,
                "GET",
                null,
                {
                    "Content-Type": "application/json",
                    "Refresh-Token": auth.refresh_token,
                    Authorization: auth.token,
                }
            );
            if (responseData.data.length === 0) {
                swal.fire({
                    title: "No roles found",
                    icon: "error",
                    confirmButtonText: "OK",
                });
                return;
            } else {
                const roles = responseData.data.map((role) => {
                    return { value: role.id, label: role.role_name };
                });
                setRoles(roles);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getRoles();
    }, []);

    const filteredRoles = useMemo(() => {
        return roles.filter(role =>
            role.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [roles, searchTerm]);

    return (
        <div className="interview-preperation-personal-interview-page">
            <div className="interview-preperation-personal-interview-page-nav-menu">
                <div
                    onClick={handleGoToHome}
                    className="interview-preperation-personal-interview-page-nav-menu-home"
                >
                    Home
                </div>
                &nbsp; &gt; &nbsp;
                <div
                    onClick={handleGoToAllInterview}
                    className="interview-preperation-personal-interview-page-nav-menu-other"
                >
                    All Interview
                </div>
                &nbsp; &gt; &nbsp;
                <div
                    onClick={() => handleGoToRoleBased(false)}
                    className="interview-preperation-personal-interview-page-nav-menu-other"
                >
                    Role Based
                </div>
            </div>
            <div className="interview-preperation-personal-interview-container">
                <div className="interview-preperation-personal-interview-header">
                    <div className="interview-preperation-personal-interview-header-title">
                        {selectedInterviewType.title}
                    </div>
                </div>
                <div className="interview-preperation-personal-interview-body">
                    <div className="interview-preperation-personal-interview-body-4">
                        <div className="interview-preperation-personal-interview-body-4-1">
                            <div className="interview-preperation-personal-interview-title">
                                Select Role
                            </div>
                            <div className="interview-preperation-personal-interview-search">
                                <div className="interview-preperation-personal-interview-search-wrapper">
                                    <input
                                        type="text"
                                        placeholder="Search roles"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        className="interview-preperation-personal-interview-search-input"
                                    />
                                    <svg className="interview-preperation-personal-interview-search-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                        <circle cx="11" cy="11" r="8"></circle>
                                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                    </svg>
                                </div>
                            </div>
                        </div>

                        {isLoading && (
                            <div className="interview-feedback-section">
                                <Loading color="#004c73" customClass="interview-feedback-interview-loading"/>
                            </div>
                        )}
                        {error && (
                            <div className="interview-preperation-personal-interview-error">
                                Something went wrong. Please try again later.
                            </div>
                        )}
                        {filteredRoles.length > 0 && (
                            <>
                                <div className="interview-preperation-personal-interview-body-4-2">
                                    <div className="interview-preperation-personal-interview-role-container-wrapper">
                                    <div className="interview-preperation-personal-interview-role-container">
                                        {filteredRoles.map((role, index) => (
                                            <div
                                                key={index}
                                                className={`interview-preperation-personal-interview-box ${selectedRole === role.value ? 'selected' : ''}`}
                                                onClick={() => setSelectedRole(role.value)}
                                            >
                                                {role.label}
                                            </div>
                                        ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="interview-preperation-personal-interview-body-4-3">
                                    <Button
                                        text="NEXT"
                                        width="400px"
                                        disabled={selectedRole === ""}
                                        onClick={onNext}
                                    />
                                </div>
                            </>
                        )}
                        {filteredRoles.length === 0 && !isLoading && !error && (
                            <div className="interview-preperation-personal-interview-no-results">
                                No roles found matching your search.
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Roles;
