import React from 'react';
import Select from "../../../../../../../../../Components/Profile/Input/Select";
import Button from "../../../../../../../../../Components/Profile/Button/Button";

const Placement = ({
    selectedInterviewType,
    startInterviewHandler,
    jobpackage,
    setSelectedPackage,
    selectedPackage,
    handleGoToHome,
    handleGoToAllInterview,
    handleGoToPlacementBased
}) => {
    return (
        <div className="interview-preperation-personal-interview-page">
            <div className="interview-preperation-personal-interview-page-nav-menu">
                <div
                    onClick={handleGoToHome}
                    className="interview-preperation-personal-interview-page-nav-menu-home"
                >
                    Home
                </div>
                &nbsp; &gt; &nbsp;
                <div
                    onClick={handleGoToAllInterview}
                    className="interview-preperation-personal-interview-page-nav-menu-other"
                >
                    All Interview
                </div>
                &nbsp; &gt; &nbsp;
                <div
                    onClick={() => handleGoToPlacementBased(false)}
                    className="interview-preperation-personal-interview-page-nav-menu-other"
                >
                    Placement Based
                </div>
            </div>
            <div className="interview-preperation-personal-interview-container">
                <div className="interview-preperation-personal-interview-header">
                    <div className="interview-preperation-personal-interview-header-title">
                        {selectedInterviewType.title}
                    </div>
                </div>
                <div className="interview-preperation-personal-interview-body">
                    <div className="interview-preperation-personal-interview-body-2">
                        <div className="interview-preperation-personal-interview-body-2-1">
                            <div className="interview-preperation-personal-interview-title">
                                {selectedInterviewType.InterviewStartTitle}
                            </div>
                        </div>

                        {jobpackage && jobpackage.length > 0 && (
                            <>
                                <div className="interview-preperation-personal-interview-body-2-2">
                                    <Select
                                        isDisabled={false}
                                        selectData={jobpackage}
                                        width="300px"
                                        onChange={(data) => setSelectedPackage(data.value)}
                                        selectLabel={true}
                                        selectLabelText="Select Package"
                                    />
                                </div>
                                <div className="interview-preperation-personal-interview-body-2-3">
                                    <Button
                                        text="NEXT"
                                        width="400px"
                                        disabled={selectedPackage === ""}
                                        onClick={() => startInterviewHandler({ companypackage: selectedPackage })}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Placement;
