import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaDownload, FaVideo, FaClock, FaCalendarAlt, FaChalkboardTeacher, FaUserCheck, FaUserTimes, FaHourglassHalf, FaPlayCircle, FaEye } from "react-icons/fa";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import moment from 'moment';
import "./Attendance.css";
import { useHttpClient } from '../../../../../../../../../Components/Hooks/http-hook';
import { AuthContext } from '../../../../../../../../../context/auth-context';
import Button from '../../../../../../../../../Components/Profile/Button/Button';

const Attendance = () => {
  const navigate = useNavigate();
  const { cid, ct } = useParams();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);

  const [attendanceData, setAttendanceData] = useState({
    totalLectures: 0,
    remainingLectures: 0,
    presentLectures: 0,
    absentLectures: 0,
    lastUpdated: "",
    attendancePercentage: 0,
    details: []
  });

  const fetchAttendanceData = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/s3/student-course-attendance-data`,
        "GET",
        null,
        {
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );

      const programData = responseData.data.find(program => program.program_id === cid);

      if (programData) {
        const transformedData = {
          totalLectures: programData.total_classes_per_program || 0,
          presentLectures: programData.total_classes_attended || 0,
          absentLectures: (programData.total_classes_per_program || 0) - (programData.total_classes_attended || 0),
          remainingLectures: 0,
          lastUpdated: new Date().toISOString(),
          attendancePercentage: Math.round((programData.total_classes_attended / programData.total_classes_per_program) * 100) || 0,
          details: programData.attendance_info.map(item => ({
            id: item.title,
            title: item.title,
            meetingTime: item.scheduled_start,
            duration: "2 hours",
            instructor: "Not specified",
            status: item.isPresent === 1 ? "Present" : "Absent",
            recordingUrl: "#"
          }))
        };

        setAttendanceData(transformedData);
      }
    } catch (err) {
      console.error("Error fetching attendance:", err);
    }
  };

  useEffect(() => {
    fetchAttendanceData();
  }, [cid]);

  const downloadAttendance = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/s3/student-attendance-data-excel/${cid}`,
        {
          method: "GET",
          headers: {
            accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "Refresh-Token": auth.refresh_token,
            Authorization: auth.token,
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = "Attendance.xlsx";
        link.click();
      }
    } catch (err) {
      console.error("Error downloading attendance:", err);
    }
  };

  const [showDetails, setShowDetails] = useState(false);

  const pieChartData = [
    { name: 'Present', value: attendanceData.presentLectures, color: '#4CAF50', icon: <FaUserCheck /> },
    { name: 'Absent', value: attendanceData.absentLectures, color: '#f44336', icon: <FaUserTimes /> },
    { name: 'Remaining', value: attendanceData.remainingLectures, color: '#9e9e9e', icon: <FaHourglassHalf /> }
  ];

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="skillbox-chart-tooltip">
          <p className="tooltip-label">{payload[0].name}</p>
          <p className="tooltip-value">{payload[0].value} Lectures</p>
          <p className="tooltip-percentage">
            {((payload[0].value / attendanceData.totalLectures) * 100).toFixed(1)}%
          </p>
        </div>
      );
    }
    return null;
  };

  const goToHome = () => {
    navigate("/skillbox-v2");
  };

  const tableRef = React.useRef(null);

  const handleShowDetails = () => {
    setShowDetails(!showDetails);

    if (!showDetails) {
      setTimeout(() => {
        tableRef.current && tableRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }, 100);
    }
  };

  const hasAttendanceData = attendanceData.totalLectures > 0;

  return (
    <div className="stu-skillbox-attendance-page">
      <div className="stu-skillbox-attendance-fixed-header">
        <div className="stu-skillbox-attendance-nav-header">
          <span onClick={goToHome}>Home</span>
        </div>

        <div className="stu-skillbox-attendance-header">
          <div className="stu-skillbox-attendance-title">
            <h1>Attendance - {ct}</h1>
            <div className="stu-skillbox-attendance-timestamp">
              <FaCalendarAlt /> Last Updated: {moment(attendanceData.lastUpdated).fromNow()}
            </div>
          </div>
          <div className="stu-skillbox-attendance-percentage">
            <div className={`stu-skillbox-attendance-status ${attendanceData.attendancePercentage >= 75 ? 'stu-skillbox-attendance-status-good' :
              attendanceData.attendancePercentage >= 60 ? 'stu-skillbox-attendance-status-average' :
                'stu-skillbox-attendance-status-poor'
              }`}>
              <span className="stu-skillbox-attendance-value">{attendanceData.attendancePercentage}%</span>
              <span className="stu-skillbox-attendance-label">Attendance</span>
            </div>
          </div>
        </div>
      </div>

      <div className="stu-skillbox-attendance-content">
        {isLoading && <div className="stu-skillbox-attendance-loading">Loading...</div>}
        {error && <div className="stu-skillbox-attendance-error">{error}</div>}
        {!isLoading && !error && !hasAttendanceData && (
          <div className="stu-skillbox-attendance-error">
            No classes scheduled for this course yet.
          </div>
        )}
        <div className="stu-skillbox-attendance-stats">
          <div className="stu-skillbox-attendance-cards">
            <div className="stu-skillbox-attendance-stat-card stu-skillbox-attendance-total">
              <FaChalkboardTeacher className="stu-skillbox-attendance-stat-icon" />
              <div className="stu-skillbox-attendance-stat-title">Total Lectures</div>
              <p>{attendanceData.totalLectures}</p>
            </div>
            <div className="stu-skillbox-attendance-stat-card stu-skillbox-attendance-remaining">
              <FaHourglassHalf className="stu-skillbox-attendance-stat-icon" />
              <div className="stu-skillbox-attendance-stat-title">Remaining</div>
              <p>{attendanceData.remainingLectures}</p>
            </div>
            <div className="stu-skillbox-attendance-stat-card stu-skillbox-attendance-present">
              <FaUserCheck className="stu-skillbox-attendance-stat-icon" />
              <div className="stu-skillbox-attendance-stat-title">Present</div>
              <p>{attendanceData.presentLectures}</p>
            </div>
            <div className="stu-skillbox-attendance-stat-card stu-skillbox-attendance-absent">
              <FaUserTimes className="stu-skillbox-attendance-stat-icon" />
              <div className="stu-skillbox-attendance-stat-title">Absent</div>
              <p>{attendanceData.absentLectures}</p>
            </div>
          </div>

          <div className="stu-skillbox-attendance-chart">
            <div className="stu-skillbox-attendance-chart-title">Attendance Distribution</div>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={pieChartData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  label={({ name, value }) => `${name}: ${value}`}
                >
                  {pieChartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
                <Legend
                  verticalAlign="bottom"
                  height={36}
                  formatter={(value, entry) => (
                    <span style={{ color: entry.color }}>
                      {pieChartData.find(item => item.name === value).icon} {value}
                    </span>
                  )}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="stu-skillbox-attendance-actions">
          <Button
            text={<><FaDownload /> Download Attendance Report</>}
            onClick={downloadAttendance}
            width="auto"
            disabled={!hasAttendanceData}
          />
          <Button
            text={<>{showDetails ? 'Hide Details' : <>View Detailed Report</>}</>}
            onClick={handleShowDetails}
            width="auto"
            disabled={!hasAttendanceData}
          />
        </div>

        {showDetails && (
          <div className="stu-skillbox-attendance-table-wrapper" ref={tableRef}>
            <table className="stu-skillbox-attendance-table">
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Class Title</th>
                  {/* <th>Instructor</th> */}
                  <th>Meeting Time</th>
                  <th>Duration</th>
                  <th>Attendance</th>
                  <th>Recording</th>
                </tr>
              </thead>
              <tbody>
                {attendanceData.details.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>
                      <div className="stu-skillbox-attendance-title-cell" title={item.title}>
                        {item.title}
                      </div>
                    </td>
                    {/* <td>
                      <div className="stu-skillbox-attendance-instructor-cell" title={item.instructor}>
                        {item.instructor}
                      </div>
                    </td> */}
                    <td>
                      <FaClock /> {moment(item.meetingTime).format('DD/MM/YY, HH:mm')}
                    </td>
                    <td>{item.duration}</td>
                    <td>
                      <span className={`stu-skillbox-attendance-status-badge stu-skillbox-attendance-status-${item.status.toLowerCase()}`}>
                        {item.status === 'Present' ? <FaUserCheck /> : <FaUserTimes />} {item.status}
                      </span>
                    </td>
                    <td>
                      <div className="stu-skillbox-recording-btn" onClick={() => window.open(item.recordingUrl, '_blank')}>
                        <FaPlayCircle /> Watch

                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Attendance;
