import React, { useState, useMemo, useEffect, useContext } from 'react';
import Button from "../../../../../../../../../Components/Profile/Button/Button";
import Loading from "../../../../../../../../../Components/Loading";
import { AuthContext } from "../../../../../../../../../context/auth-context";
import { useHttpClient } from "../../../../../../../../../Components/Hooks/http-hook";
const swal = require("sweetalert2");

const Course = ({
    selectedInterviewType,
    setSelectedCourse,
    selectedCourse,
    handleGoToHome,
    handleGoToAllInterview,
    handleGoToCourseBased,
    onNext
}) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [courses, setCourses] = useState([]);
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();

    const getCourses = async () => {
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/api/interview_prep/get_all_interview_preparation_courses`,
                "GET",
                null,
                {
                    "Content-Type": "application/json",
                    "Refresh-Token": auth.refresh_token,
                    Authorization: auth.token,
                }
            );
            if (responseData.data.length === 0) {
                swal.fire({
                    title: "No courses found",
                    icon: "error",
                    confirmButtonText: "OK",
                });
                return;
            }
            const formattedCourses = responseData.data.map((course) => ({
                value: course.id,
                label: course.course_name
            }));
            setCourses(formattedCourses);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getCourses();
    }, []);

    const filteredCourses = useMemo(() => {
        return courses.filter(course =>
            course.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [courses, searchTerm]);

    return (
        <div className="interview-preperation-personal-interview-page">
            <div className="interview-preperation-personal-interview-page-nav-menu">
                <div
                    onClick={handleGoToHome}
                    className="interview-preperation-personal-interview-page-nav-menu-home"
                >
                    Home
                </div>
                &nbsp; &gt; &nbsp;
                <div
                    onClick={handleGoToAllInterview}
                    className="interview-preperation-personal-interview-page-nav-menu-other"
                >
                    All Interview
                </div>
                &nbsp; &gt; &nbsp;
                <div
                    onClick={() => handleGoToCourseBased(false)}
                    className="interview-preperation-personal-interview-page-nav-menu-other"
                >
                    Course Based
                </div>
            </div>
            <div className="interview-preperation-personal-interview-container">
                <div className="interview-preperation-personal-interview-header">
                    <div className="interview-preperation-personal-interview-header-title">
                        {selectedInterviewType.title}
                    </div>
                </div>
                <div className="interview-preperation-personal-interview-body">
                    <div className="interview-preperation-personal-interview-body-4">
                        <div className="interview-preperation-personal-interview-body-4-1">
                            <div className="interview-preperation-personal-interview-title">
                                Select Course
                            </div>
                            <div className="interview-preperation-personal-interview-search">
                                <div className="interview-preperation-personal-interview-search-wrapper">
                                    <input
                                        type="text"
                                        placeholder="Search courses"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        className="interview-preperation-personal-interview-search-input"
                                    />
                                    <svg className="interview-preperation-personal-interview-search-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                        <circle cx="11" cy="11" r="8"></circle>
                                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        {isLoading && (
                            <div className="interview-feedback-section">
                                <Loading color="#004c73" customClass="interview-feedback-interview-loading"/>
                            </div>
                        )}
                        
                        {error && (
                            <div className="interview-preperation-personal-interview-error">
                                Something went wrong. Please try again later.
                            </div>
                        )}

                        {!isLoading && !error && filteredCourses.length > 0 && (
                            <>
                                <div className="interview-preperation-personal-interview-body-4-2">
                                    <div className="interview-preperation-personal-interview-role-container-wrapper">
                                    <div className="interview-preperation-personal-interview-role-container">
                                        {filteredCourses.map((course, index) => (
                                            <div
                                                key={index}
                                                className={`interview-preperation-personal-interview-box ${selectedCourse === course.value ? 'selected' : ''}`}
                                                onClick={() => setSelectedCourse(course.value)}
                                            >
                                                {course.label}
                                            </div>
                                        ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="interview-preperation-personal-interview-body-4-3">
                                    <Button
                                        text="NEXT"
                                        width="400px"
                                        disabled={selectedCourse === ""}
                                        onClick={onNext}
                                    />
                                </div>
                            </>
                        )}
                        {!isLoading && !error && filteredCourses.length === 0 && (
                            <div className="interview-preperation-personal-interview-no-results">
                                No courses found matching your search.
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Course;
