import React from "react";
import { useState, useEffect, useContext } from "react";
import "./Practice.css";
import InterviewFeedback from "./InterviewFeedback/InterviewFeedback";
import PracticeType from "./PracticeType/PracticeType";
import PracticeDash from "./PracticeDash/PracticeDash";

const Practice = ({ setSelectedOption }) => {
  const [selectedTab, setSelectedTab] = useState("interviewHome");
  const [selectedInterviewType, setSelectedInterviewType] = useState({
    type_slug: "",
    title: "",
    cover: null,
    image: null,
    keyPoints: [],
  });

  const renderSelectedTab = () => {
    if (selectedTab === "interviewHome") {
      return (
        <PracticeType
          setSelectedTab={setSelectedTab}
          setSelectedOption={setSelectedOption}
          setSelectedInterviewType={setSelectedInterviewType}
        />
      );
    }
    else if (selectedTab === "personalInterview") {
      return (
        <PracticeDash
          setSelectedTab={setSelectedTab}
          setSelectedOption={setSelectedOption}
          selectedInterviewType={selectedInterviewType}
        />
      );
    } else if (selectedTab === "interviewFeedback") {
      return (
        <InterviewFeedback
          setSelectedTab={setSelectedTab}
          setSelectedOption={setSelectedOption}
        />
      );
    }
  };

  return <div>{renderSelectedTab()}</div>;
};
export default Practice;
