import React, { useState, useEffect, useContext } from "react";
import "./PersonalInterview.css";
import TickSvg from "../../../assets/svgs/TickSvg";
import Popup from "../../../../../../Components/Profile/Popup/PopUp";
import InterviewStart from "./Pages/InterviewStart";
import BestOfLuck from "./Pages/BestOfLuck";
import InterviewPlatform from "./Pages/InterviewPlatform";
import { AuthContext } from "../../../../../../context/auth-context";
import { useHttpClient } from "../../../../../../Components/Hooks/http-hook";
import InterviewTabs from "../InterviewTabs/InterviewTabs";
import InterviewInstructions from './Components/InterviewInstructions';
const swal = require("sweetalert2");

const level = [
  { value: "fresher", label: "Fresher" },
  { value: "mid", label: "Mid-Level" },
  { value: "senior", label: "Senior" },
];

const questionLevel = [
  { value: "beginner", label: "Beginner" },
  { value: "intermediate", label: "Intermediate" },
  { value: "advanced", label: "Advanced" },
];

const jobpackage = [
  { value: "4lpa", label: "< 4 LPA" },
  { value: "7lpa", label: "4 - 7 LPA" },
];

const PersonalInterview = ({
  setSelectedTab,
  setSelectedOption,
  selectedInterviewType,
}) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { isLoading: isLoadingStartInterview, error: errorStartInterview, sendRequest: sendRequestStartInterview, clearError: clearErrorStartInterview } = useHttpClient();
  const [showInterviewPopup, setShowInterviewPopup] = useState(false);
  const [showInterviewBestOfLuckPopup, setShowInterviewBestOfLuckPopup] = useState(false);
  const [showInterviewStartPopup, setShowInterviewStartPopup] = useState(false);
  const [showInterviewPlatformPopup, setShowInterviewPlatformPopup] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedQuestionLevel, setSelectedQuestionLevel] = useState("");
  const [selectedSkill, setSelectedSkill] = useState("");
  const [selectedPackage, setSelectedPackage] = useState("");
  const [resume, setResume] = useState("");
  const [examId, setExamId] = useState("");
  const [questions, setQuestions] = useState([]);
  const [examData, setExamData] = useState({});
  const [showInstructions, setShowInstructions] = useState(false);
  const [pendingInterviewData, setPendingInterviewData] = useState(null);

  const goToAllInterview = () => {
    setSelectedTab("interviewHome");
  };

  const goToFeedback = () => {
    setSelectedTab("interviewFeedback");
  };

  const goToHome = () => {
    setSelectedOption({
      value: "skillbox",
      label: "Skillbox",
    });
  };

  const getInterviewData = async (examId) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/interview_prep/get_interview_preperation_exam_details_v2/${examId}`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      if (responseData.data.length === 0) {
        swal.fire({
          title: "No questions found",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      } else {
        const questionData = responseData.data.questions.map((question, i) => {
          return {
            id: i + 1,
            question_id: question.question_id,
            question: question.question,
          };
        });
        setQuestions(questionData);
        setExamData(responseData.data.exam);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const startInterviewApi = async (role, level) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/interview_prep/start_interview_preparation_exam`,
        "POST",
        JSON.stringify({
          role_id: role,
          level: level,
        }),
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      const exam_id = responseData.data;
      if (exam_id) {
        await getInterviewData(exam_id);
        setExamId(responseData.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const startInterviewApiNew = async (
    role = null,
    level = null,
    joblevel = null,
    skill = null,
    resume = null,
    companypackage = null,
    course = null
  ) => {
    try {
      const formData = new FormData();
      formData.append("type", selectedInterviewType.type_slug);
      formData.append("role_id", role);
      formData.append("level", level);
      formData.append("joblevel", joblevel);
      formData.append("skill", skill);
      if (resume) {
        formData.append("resume", resume);
      }
      formData.append("companypackage", companypackage);
      formData.append("course", course);

      const responseData = await sendRequestStartInterview(
        `${process.env.REACT_APP_BACKEND_URL}/api/interview_prep/start_interview_preparation_exam`,
        "POST",
        formData,
        {
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );

      const exam_id = responseData.data;
      await getInterviewData(exam_id);
      setExamId(responseData.data);
    } catch (error) {
      console.log(error);
    }
  };

  const startInterview = (role, level) => {
    startInterviewApi(role, level);
    setShowInterviewPopup(true);
    setShowInterviewStartPopup(true);
  };

  const startInterviewHandler = (data) => {
    setPendingInterviewData(data);
    setShowInstructions(true);
  };

  const handleInstructionsClose = () => {
    setShowInstructions(false);
    setPendingInterviewData(null);
  };

  const handleInstructionsConfirm = async () => {
    const { role, level, joblevel, skill, resume, companypackage, course } = pendingInterviewData;
    
    try {
      await startInterviewApiNew(role, level, joblevel, skill, resume, companypackage, course);
      setShowInstructions(false);
      setShowInterviewPopup(true);
      setShowInterviewStartPopup(true);
    } catch (error) {
      console.error('Error starting interview:', error);
    }
  };

  const closeInterviewPopup = () => {
    setShowInterviewPopup(false);
    setShowInterviewStartPopup(false);
    setShowInterviewBestOfLuckPopup(false);
    setShowInterviewPlatformPopup(false);
  };

  return (
    <div className="personal-interview-page">
      {showInterviewPopup && examData && (
        <Popup
          show={true}
          onClose={closeInterviewPopup}
          // title={
          //   showInterviewStartPopup &&
          //   `Attempt: ${examData.attempt_number} | Role: ${examData.role} | Level : ${examData.level}`
          // }
          title={
            showInterviewStartPopup && (
              <>
                {!errorStartInterview ? (
                  <span style={{ color: '#4CAF50' }}>Your exam created successfully!</span>
                ) : (
                  <span style={{ color: '#f44336' }}>Wasn't able to create exam. Please try again.</span>
                )}
              </>
            )
          }
        >
          {showInterviewStartPopup && (
            <InterviewStart
              totalNoOfQuestions={questions.length}
              setShowInterviewBestOfLuckPopup={setShowInterviewBestOfLuckPopup}
              setShowInterviewStartPopup={setShowInterviewStartPopup}
              examId={examId}
              setQuestions={setQuestions}
              onRetry={() => {
                handleInstructionsConfirm();
              }}
              isRetrying={isLoadingStartInterview}
              isError={errorStartInterview}
            />
          )}
          {showInterviewBestOfLuckPopup && (
            <BestOfLuck
              setShowInterviewPlatformPopup={setShowInterviewPlatformPopup}
              setShowInterviewBestOfLuckPopup={setShowInterviewBestOfLuckPopup}
            />
          )}
          {showInterviewPlatformPopup && (
            <InterviewPlatform
              questions={questions}
              examId={examId}
              setSelectedTab={setSelectedTab}
            />
          )}
        </Popup>
      )}
      {showInstructions && (
        <Popup
          show={true}
          onClose={handleInstructionsClose}
        >
          <InterviewInstructions
            onClose={handleInstructionsClose}
            onStart={handleInstructionsConfirm}
            isLoading={isLoadingStartInterview}
          />
        </Popup>
      )}
      <div className="personal-interview-container">
        <InterviewTabs
          selectedInterviewType={selectedInterviewType}
          isLoading={isLoading}
          error={error}
          questionLevel={questionLevel}
          level={level}
          jobpackage={jobpackage}
          setSelectedLevel={setSelectedLevel}
          setSelectedSkill={setSelectedSkill}
          setSelectedQuestionLevel={setSelectedQuestionLevel}
          setSelectedPackage={setSelectedPackage}
          setResume={setResume}
          selectedLevel={selectedLevel}
          selectedSkill={selectedSkill}
          selectedQuestionLevel={selectedQuestionLevel}
          selectedPackage={selectedPackage}
          resume={resume}
          startInterviewHandler={startInterviewHandler}
          handleGoToHome={goToHome}
          handleGoToAllInterview={goToAllInterview}
          goToFeedback={goToFeedback}
        />
      </div>
    </div>
  );
};

export default PersonalInterview;
